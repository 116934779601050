<template>
  <b-card>
    <header>
      <h4>{{ titulo }}</h4>
      <hr />
    </header>

    <main>
      <slot></slot>
    </main>
  </b-card>
</template>

<script>
export default {
  name: "main-template",
  props: {
    titulo: {
      type: String,
      required: true,
    },
  },
};
</script>