<template>
  <div class="container-xxl">
    <b-card v-if="!userWithoutArea" class="mt-2">
      <b-col cols="12">
        <!-- parte superior -->
        <b-row>
          <b-col cols="12" md="6" lg="6" xl="6" class="pl-0">
            <h5>{{ `Tickets ${isList ? "- Listado" : ""}` }}</h5>
          </b-col>
          <b-col align="right" sm="6" md="3" lg="3" xl="3" class="pl-0">
          </b-col>
          <!-- botones -->
          <b-col align="right" sm="6" md="3" lg="3" xl="3" class="pl-0 pr-0">
            <b-button
              size="sm"
              v-if="!isList"
              @click="
                isList = true;
                order = {
                  id: null,
                  order_status: null,
                  area: null,
                  description: null,
                  files: [],
                  observations: [],
                };
                updateRecords();
              "
              variant="outline-info"
            >
              <b-icon
                title="Listado"
                icon="card-list"
                variant="primary"
              ></b-icon>
            </b-button>
            <b-button
              class="ml-3"
              title="Actualizar"
              size="sm"
              @click="updateRecords"
              variant="outline-primary"
            >
              <b-icon icon="arrow-clockwise" variant="primary"></b-icon>
            </b-button>
            <b-button
              title="Nuevo Ticket"
              class="ml-3"
              size="sm"
              @click="goToNewOrder"
              variant="outline-primary"
            >
              <b-icon icon="plus-circle" variant="primary"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </b-col>

      <b-col class="mt-2" v-if="isList">
        <b-overlay
          :show="showLoading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="lg"
        >
          <b-overlay
            :show="showLoadingFilter"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="lg"
          >
            <b-row
              align-v="center"
              class="pt-2 pb-2 mb-2"
              style="background-color: #f9f9f9"
            >
              <!-- boton y input de buscado -->
              <b-col sm="2">
                <b-form-text class="mt-0"> BÚSQUEDA </b-form-text>
                <b-input-group size="sm">
                  <b-input-group-prepend is-text>
                    <b-icon icon="search"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="filter"
                    size="sm"
                    type="search"
                    placeholder="Buscar..."
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-col sm="1">
                <b-form-text class="mt-0"> CANTIDAD </b-form-text>
                <b-input-group size="sm">
                  <b-form-select
                    size="sm"
                    v-model="seletPaginado"
                    :options="optionsPaginado"
                    @change="reloadData"
                  ></b-form-select>
                </b-input-group>
              </b-col>
              <!-- filtros -->
              <b-col sm="1">
                <b-form-text class="mt-0"> ESTADO </b-form-text>
                <b-form-select
                  size="sm"
                  v-model="listStatusSelected"
                  :options="listStatus"
                  required
                  @change="setList()"
                ></b-form-select>
              </b-col>
              <b-col
                v-if="!isFilterObservation"
                align="right"
                sm="8"
                class="pt-3"
              >
                <b-button
                  @click="getOriginAreas()"
                  title="Abrir FIltro por observación"
                  size="sm"
                  variant="outline-primary"
                >
                  <i class="fas fa-filter"></i>
                  <i class="fab fa-searchengin"></i>
                </b-button>
              </b-col>
              <b-col v-else align="right" sm="8">
                <b-row align-h="end">
                  <b-col class="filter-observation pb-1" sm="3" align="left">
                    <b-form-text class="mt-0">
                      BÚSQUEDA POR OBSERVACIÓN
                    </b-form-text>
                    <b-input-group size="sm">
                      <b-input-group-prepend is-text>
                        <b-icon icon="search"></b-icon>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="filterObservation"
                        size="sm"
                        type="search"
                        placeholder="Buscar..."
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col
                    sm="3"
                    align="left"
                    class="pb-1 pr-0 filter-observation"
                  >
                    <b-form-text class="mt-0"> ÁREA ORIGEN </b-form-text>
                    <b-form-select
                      size="sm"
                      v-model="originAreaSelected"
                      :options="optionsAreas"
                      required
                      @change="setList()"
                    ></b-form-select>
                  </b-col>
                  <b-col
                    sm="2"
                    align="left"
                    class="pb-1 pt-3 pl-0 filter-observation"
                  >
                    <b-button
                      title="Buscar Tickets por Observación"
                      class="ml-3"
                      size="sm"
                      variant="outline-primary"
                      :disabled="!originAreaSelected || !filterObservation"
                      @click="getTicketsByObservation()"
                    >
                      BUSCAR
                    </b-button>
                  </b-col>
                  <b-col sm="1" class="pb-1 pt-3 filter-observation">
                    <b-button
                      @click="
                        isFilterObservation = !isFilterObservation;
                        getOrders();
                      "
                      title="Cerrar Filtro"
                      class="ml-3"
                      size="sm"
                      variant="outline-primary"
                    >
                      <i class="fas fa-window-close"></i>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-overlay>
          <!-- vista de tablas en nav -->
          <b-row>
            <b-card>
              <template #header>
                <b-nav card-header tabs>
                  <b-nav-item
                    v-if="!area.non_resolving"
                    @click="changeView('Recibidos')"
                    id="Recibidos"
                    active
                    >Recibidos</b-nav-item
                  >
                  <b-nav-item @click="changeView('Realizados')" id="Realizados"
                    >Realizados</b-nav-item
                  >
                  <b-nav-item @click="changeView('Derivados')" id="Derivados"
                    >Derivados</b-nav-item
                  >
                </b-nav>
              </template>
              <!--div class="contador">
                <small class="badge-danger"
                  >Pendientes: {{ cantodaEnPendiente }}</small
                >
                <small class="badge-secondary"
                  >Leido: {{ cantidadLeido }}</small
                >
                <small class="badge-info"
                  >En Progreso: {{ cantidadEnProceso }}
                </small>
                <small class="badge-success"
                  >Finalizado: {{ cantidadFinalizado }}</small
                >
              </div-->

              <b-card-text>
                <div v-if="vista == 'Recibidos' && !area.non_resolving">
                  <!--b-col cols="12" class="subtitle">
                    <b-row>
                      <b-col md="7" sm="7" cols="6">
                        <h6 class="pl-3 mt-1">Recibidos</h6>
                      </b-col>
                    </b-row>
                  </b-col-->

                  <!-- contenido -->
                  <b-col md="12" class="p-0">
                    <b-table
                      class="table-rotation"
                      responsive
                      head-variant="light"
                      fixed
                      bordered
                      small
                      striped
                      hoverstriped
                      hover
                      :filter-included-fields="[
                        'id',
                        'description',
                        'status_name',
                        'origin_area_name',
                        'name',
                        'user_name',
                      ]"
                      :filter="filter"
                      :items="itemsReceived"
                      :fields="fieldsReceived"
                      :sort-compare="sortDatesMainTable"
                    >
                      <template #cell(categories)="row">
                          {{
                            getCategoryField(row.item.categories)
                          }}
                      </template>
                      <template #cell(description)="row">
                        <b :title="`${row.item.description}`">
                          {{
                            row.item.description.length >= 45
                              ? `${row.item.description.slice(0, 45)}...`
                              : row.item.description
                          }}
                        </b>
                      </template>
                      <template #cell(status_name)="row">
                        <b-badge :variant="getStatus(row.item.order_status, 1)">
                          <template v-if="row.item.order_status != 6">
                            {{ row.item.status_name }}
                            <i
                              v-if="row.item.type == 'consulta bancaria'"
                              class="fas fa-exclamation"
                            ></i>
                          </template>
                          <template v-else>
                            finalizado <i class="fas fa-check-circle"></i>
                          </template>
                        </b-badge>
                      </template>
                      <template #cell(actions)="row">
                        <b-icon
                          @click="
                            getDetailOrder(row.item, row.index, true, false)
                          "
                          icon="eye-fill"
                          class="border border-info rounded h6 m-0 pointer"
                          variant="info"
                        ></b-icon>
                      </template>
                    </b-table>
                  </b-col>
                </div>

                <div v-if="vista == 'Realizados'">
                  <!--b-col cols="12" class="subtitle">
                    <b-row>
                      <b-col md="7" sm="7" cols="6">
                        <h6 class="pl-3 mt-1">Realizados</h6>
                      </b-col>
                    </b-row>
                  </b-col-->

                  <b-col md="12" class="p-0">
                    <b-table
                      class="table-rotation"
                      responsive
                      head-variant="light"
                      fixed
                      bordered
                      small
                      striped
                      hoverstriped
                      hover
                      :filter="filter"
                      :filter-included-fields="[
                        'id',
                        'description',
                        'status_name',
                        'origin_area_name',
                        'name',
                        'user_name',
                      ]"
                      :items="itemsRealize"
                      :fields="fieldsRealized"
                      :sort-compare="sortDatesMainTable"
                    >
                      <template #cell(description)="row">
                        <b :title="`${row.item.description}`">
                          {{
                            row.item.description.length >= 45
                              ? `${row.item.description.slice(0, 45)}...`
                              : row.item.description
                          }}
                        </b>
                      </template>

                      <template #cell(status_name)="row">
                        <b-badge :variant="getStatus(row.item.order_status, 1)">
                          <template v-if="row.item.order_status != 6">
                            {{ row.item.status_name }}
                            <i
                              v-if="row.item.type == 'consulta bancaria'"
                              class="fas fa-exclamation"
                            ></i>
                          </template>
                          <template v-else>
                            finalizado <i class="fas fa-check-circle"></i>
                          </template>
                        </b-badge>
                      </template>

                      <template #cell(actions)="row">
                        <b-icon
                          @click="
                            getDetailOrder(row.item, row.index, false, false)
                          "
                          icon="eye-fill"
                          class="border border-info rounded h6 m-0 pointer"
                          variant="info"
                        ></b-icon>
                      </template>
                    </b-table>
                  </b-col>
                </div>

                <div v-if="vista == 'Derivados'">
                  <!--b-col cols="12" class="subtitle">
                    <b-row>
                      <b-col md="7" sm="7" cols="6">
                        <h6 class="pl-3 mt-1">Derivados</h6>
                      </b-col>
                    </b-row>
                  </b-col-->

                  <b-col md="12" class="p-0">
                    <b-table
                      class="table-rotation"
                      responsive
                      head-variant="light"
                      fixed
                      bordered
                      small
                      striped
                      hoverstriped
                      hover
                      :filter="filter"
                      :filter-included-fields="[
                        'id',
                        'description',
                        'status_name',
                        'origin_area_name',
                        'name',
                        'user_name',
                      ]"
                      :items="itemsDerive"
                      :fields="fields"
                      :sort-compare="sortDatesMainTable"
                    >
                      <template #cell(description)="row">
                        <b :title="`${row.item.description}`">
                          {{
                            row.item.description.length >= 45
                              ? `${row.item.description.slice(0, 45)}...`
                              : row.item.description
                          }}
                        </b>
                      </template>
                      <template #cell(status_name)="row">
                        <b-badge :variant="getStatus(row.item.order_status, 1)">
                          <template v-if="row.item.order_status != 6">
                            {{ row.item.status_name }}
                            <i
                              v-if="row.item.type == 'consulta bancaria'"
                              class="fas fa-exclamation"
                            ></i>
                          </template>
                          <template v-else>
                            finalizado <i class="fas fa-check-circle"></i>
                          </template>
                        </b-badge>
                      </template>
                      <template #cell(actions)="row">
                        <b-icon
                          @click="
                            getDetailOrder(row.item, row.index, false, true)
                          "
                          icon="eye-fill"
                          class="border border-info rounded h6 m-0 pointer"
                          variant="info"
                        ></b-icon>
                      </template>
                    </b-table>
                  </b-col>
                </div>
              </b-card-text>
            </b-card>
          </b-row>
        </b-overlay>
      </b-col>
      <b-col v-else cols="12" class="pt-3 card">
        <b-overlay
          :show="showLoadingDetail"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="lg"
        >
          <div>
            <b-row>
              <b-col cols="10">
                <h6>Detalle del Ticket {{ order.id }}</h6>
              </b-col>
            </b-row>

            <b-col>
              <b-row>
                <b-col class="txt-info" cols="7">
                  <b-col class="p-1">
                    <b-row>
                      <b-col style="color: blue" cols="4">
                        <b>ORIGEN</b>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="p-1">
                    <b-row>
                      <b-col cols="4"> ÁREA: </b-col>
                      <b-col cols="8">
                        <b>{{ order.origin_area_name }}</b>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="p-1">
                    <b-row>
                      <b-col cols="4"> CREADOR: </b-col>
                      <b-col cols="8">
                        <b>{{ order.user }}</b>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="p-1">
                    <b-row>
                      <b-col cols="4"> DESCRIPCIÓN: </b-col>
                      <b-col cols="8">
                        <b>{{ order.description }}</b>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="p-1">
                    <b-row>
                      <b-col cols="4"> ESTADO: </b-col>
                      <b-col cols="8">
                        <b-row align-h="start">
                          <b-col cols="2">
                            <b-badge
                              id="popover-target-1"
                              :variant="getStatus(order.status, 1)"
                            >
                              {{ getStatus(order.status) }}
                            </b-badge>
                            <b-popover
                              v-if="order.hasOwnProperty('seen_by_users')"
                              target="popover-target-1"
                              triggers="hover"
                              placement="rightbottom"
                            >
                              <div>
                                <div
                                  v-for="(item, index) in order.seen_by_users"
                                  :key="index"
                                >
                                  <b-badge variant="light">
                                    {{ item }}
                                  </b-badge>
                                </div>
                              </div>
                            </b-popover>
                          </b-col>
                          <b-col v-if="order.hasOwnProperty('user_assigned')">
                            <b-badge variant="light">
                              {{ order.user_assigned }}
                            </b-badge>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-col>
                <b-col class="txt-info" cols="5">
                  <b-col class="p-1">
                    <b-row>
                      <b-col style="color: blue" cols="4">
                        <b>DESTINO</b>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="p-1">
                    <b-row>
                      <b-col cols="4"> ÁREA: </b-col>
                      <b-col cols="8">
                        <b>{{ order.area }}</b>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="p-1">
                    <b-row>
                      <b-col cols="4"> FECHA INICIO: </b-col>
                      <b-col cols="8">
                        <b>{{ order.created_at }}</b>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="p-1">
                    <b-row>
                      <b-col cols="4"> FECHA FIN: </b-col>
                      <b-col v-if="order.status == 3" cols="8">
                        <b>{{ order.updated_at }}</b>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-col>
                <b-col cols="5"> </b-col>
              </b-row>
              <b-row>
                <b-col cols="9" class="pt-3 mb-0">
                  <h6>Historial</h6>
                </b-col>
                <b-col
                  v-if="order.status < 3"
                  align="right"
                  cols="3"
                  class="pt-3 pr-0 mb-0"
                >
                  <b-button
                    @click="newObservation()"
                    size="sm"
                    variant="outline-primary"
                  >
                    <b-icon icon="plus-circle" variant="primary"></b-icon>
                    Acciones
                  </b-button>
                </b-col>
                <!-- Si está finalizado y es contaduría -->
                <b-col
                  v-if="
                    order.status == 3 &&
                    order.area_id == 3 &&
                    area.id == 3 &&
                    order.type == 'consulta bancaria'
                  "
                  align="right"
                  cols="3"
                  class="pt-3 pr-0 mb-0"
                >
                  <b-form-checkbox
                    v-model="isFinishWithOk"
                    :value="true"
                    :unchecked-value="false"
                    @change="confirmVerifyAndFinish()"
                  >
                    Finalizado y Verificado
                  </b-form-checkbox>
                </b-col>
                <b-col class="observations p-2" cols="12" md="12" xl="12">
                  <b-col v-for="(item, index) in historyAux" :key="index">
                    <b-row class="p-1 history-item">
                      <b-col cols="3" style="color: gray">
                        {{ item.created_at }}
                      </b-col>
                      <b-col v-if="index == 0" cols="9">
                        <b-badge variant="info">creado</b-badge>
                        por
                        <b-badge variant="light">{{ item.name_user }}</b-badge>
                        para
                        <b-badge variant="warning">{{ item.name }}</b-badge>
                      </b-col>
                      <b-col class="p-0" v-else>
                        <b-col v-if="item.status == 5" cols="12">
                          <b-badge variant="info">derivado</b-badge>
                          a
                          <b-badge variant="warning">{{ item.name }}</b-badge>
                          por
                          <b-badge variant="light">{{
                            item.name_user
                          }}</b-badge>
                        </b-col>
                        <b-col v-else-if="item.status == 1" cols="12">
                          <b-badge variant="info">leído</b-badge>
                          por
                          <b-badge variant="light">{{
                            item.name_user
                          }}</b-badge>
                        </b-col>
                        <b-col v-else-if="item.status == 2" cols="12">
                          <b-badge variant="info">tomado</b-badge>
                          por
                          <b-badge variant="light">{{ item.name_user }}</b-badge
                          >, se encuentra en proceso...
                        </b-col>
                        <b-col v-else-if="item.status == 3" cols="12">
                          <b-badge variant="info">finalizado</b-badge>
                          por
                          <b-badge variant="light">{{
                            item.name_user
                          }}</b-badge>
                        </b-col>
                        <b-col v-else-if="item.status == 6" cols="12">
                          <b-badge variant="info">verificado</b-badge>
                          por
                          <b-badge variant="light">{{
                            item.name_user
                          }}</b-badge>
                        </b-col>
                        <b-col v-else-if="item.status == 4" cols="12">
                          <b-badge variant="info">rechazado</b-badge>
                          por
                          <b-badge variant="light">{{
                            item.name_user
                          }}</b-badge>
                        </b-col>
                      </b-col>
                      <b-col
                        v-if="item.hasOwnProperty('observation')"
                        cols="8"
                        style="color: gray"
                      >
                        {{ item.user.name }}:
                        {{ item.observation }}
                      </b-col>
                      <!-- ARCHIVOS -->
                      <b-col cols="12">
                        <b-row>
                          <b-col cols="3"> </b-col>
                          <b-col
                            class="files files-detail"
                            cols="8"
                            md="8"
                            xl="8"
                            v-if="item.hasOwnProperty('files') && item.files.length"
                          >
                            <b-row align-h="start">
                              <b-col
                                v-for="(file, index) in item.files"
                                :key="index"
                                class="col-1 p-0"
                                @click="showCarousel(index, item.files)"
                              >
                                <i
                                  v-if="isImage(file.extension)"
                                  class="fas fa-image pointer icon-detail"
                                ></i>
                                <i
                                  v-else-if="file.extension == 'pdf'"
                                  class="fas fa-file-pdf pointer icon-detail"
                                ></i>
                                <i
                                  v-else-if="
                                    file.extension == 'xlsx' ||
                                    file.extension == 'xls'
                                  "
                                  class="fas fa-file-excel pointer icon-detail"
                                ></i>
                                <i
                                  v-else-if="
                                    file.extension == 'docx' ||
                                    file.extension == 'doc'
                                  "
                                  class="fas fa-file-excel pointer icon-detail"
                                ></i>
                                <i
                                  v-else-if="file.extension == 'csv'"
                                  class="fas fa-file-csv pointer icon-detail"
                                ></i>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="pt-3 mb-0">
                  <h6>Archivos</h6>
                </b-col>
                <b-col
                  class="files"
                  cols="12"
                  md="12"
                  xl="12"
                  v-if="order.files.length"
                >
                  <b-row class="pl-2">
                    <b-col
                      align="center"
                      v-for="(file, index) in order.files"
                      :key="index"
                      class="col-1 p-0"
                      @click="showCarousel(index, order.files)"
                    >
                      <div
                        v-if="isImage(file.extension)"
                        class="image-container"
                      >
                        <b-img
                          rounded
                          thumbnail
                          :src="`https://api.ticketsflow.online/${getStoragePath(
                            file.path
                          )}`"
                          fluid
                          alt="Responsive image"
                          class="image"
                        ></b-img>
                      </div>
                      <i
                        v-else-if="file.extension == 'pdf'"
                        class="fas fa-file-pdf img-preview"
                      ></i>
                      <i
                        v-else-if="
                          file.extension == 'xlsx' || file.extension == 'xls'
                        "
                        class="fas fa-file-excel img-preview"
                      ></i>
                      <i
                        v-else-if="
                          file.extension == 'docx' || file.extension == 'doc'
                        "
                        class="fas fa-file-excel img-preview"
                      ></i>
                      <i
                        v-else-if="file.extension == 'csv'"
                        class="fas fa-file-csv img-preview"
                      ></i>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col style="font-size: 10pt" class="files" cols="12" v-else>
                  No se cargaron imágenes
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-overlay>
      </b-col>
    </b-card>
    <div v-else>Usuario sun Área asignada</div>
    <!-- MODAL Derivar -->
    <b-modal
      id="modal-derive"
      title="Administrar Ticket"
      hide-footer
      centered
      :no-close-on-backdrop="true"
    >
      <b-col sm="12" md="12">
        <b-form-text for="select-state"> Área </b-form-text>
        <b-form-select
          size="sm"
          v-model="selectArea"
          :options="optionsAreas"
          @change="getUsersArea()"
        ></b-form-select>
      </b-col>
      <b-col v-if="this.area.id == this.selectArea" sm="12" md="12">
        <b-form-text for="select-state"> Usuario </b-form-text>
        <b-form-select
          size="sm"
          v-model="selectUser"
          :options="optionsUsers"
        ></b-form-select>
      </b-col>
      <b-col cols="12" align="center" class="p-3">
        <b-form-textarea
          id="textarea"
          placeholder="Escribir al menos 10 caracteres..."
          rows="3"
          max-rows="6"
          autofocus
          v-model="observationDerive"
        ></b-form-textarea>
      </b-col>
      <b-row>
        <b-col align="left" cols="6">
          <b-button class="mt-3" @click="$bvModal.hide('modal-derive')"
            >Cancelar</b-button
          >
        </b-col>
        <b-col align="right" cols="6">
          <b-button class="mt-3" @click="deriveOrder()" variant="primary"
            >Derivar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- MODAL Observation -->
    <b-modal
      id="modal-observation"
      title="Nueva Acción"
      hide-footer
      centered
      :no-close-on-backdrop="true"
    >
      <b-overlay
        :show="showLoadingModal"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="lg"
      >
        <b-col cols="12" align="center" class="pr-0 pl-0">
          <b-form-textarea
            id="textarea"
            placeholder="Escriba una Observación y tome una Acción"
            rows="3"
            max-rows="6"
            autofocus
            maxlength="300"
            v-model="observation"
            @input="checkLenght()"
          ></b-form-textarea>
        </b-col>
        <div align="right" style="font-size: 8pt" variant="light">
          {{ observation ? observation.length : 0 }}-300
        </div>
        <b-col class="p-0 pt-1">
          <b-col sm="12" md="12" class="p-0">
            <b-form-text for="select-state"> Foto/Archivo </b-form-text>
            <b-form-file
              @input="checkFile"
              multiple
              v-model="files"
              placeholder="Seleccione los archivos..."
              accept=".webp, .jpeg, .jpg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv"
            >
            </b-form-file>
            <b-col cols="12" class="p-0">
              <b-row style="border-style: none">
                <b-col
                  cols="10"
                  style="font-size: 8pt; color: gray"
                  variant="light"
                >
                  El tamaño máximo permitido para cada archivo es de 3MB, con un
                  límite de 5 archivos en total.
                </b-col>
                <b-col
                  align="right"
                  cols="2"
                  style="font-size: 8pt; color: gray"
                  variant="light"
                >
                  {{ filesAccumulated.length }}-5
                </b-col>
              </b-row>
            </b-col>
            <div>
              <ul class="p-0">
                <div v-for="(thisFile, index) in filesAccumulated" :key="index">
                  <b-badge
                    @click="filesAccumulated.splice(index, 1)"
                    href="#"
                    variant="info"
                    ><i class="fas fa-trash-alt"></i>
                    {{ thisFile.name }}</b-badge
                  >
                </div>
              </ul>
            </div>
          </b-col>
        </b-col>
        <b-row v-if="isBtnObservationDerive">
          <b-col align="left" cols="2" class="pr-2">
            <b-button
              class="mt-3"
              @click="$bvModal.hide('modal-observation')"
              size="sm"
              >Cancelar</b-button
            >
          </b-col>
          <b-col v-if="isListReceived || isClicListDerive" cols="7">
            <b-row>
              <b-col align="right" cols="3" class="pr-2">
                <b-button
                  v-if="
                    observation.length > 1 &&
                    order.status < 3 &&
                    !isClicListDerive
                  "
                  class="mt-3"
                  :disabled="isClicListDerive"
                  @click="
                    isBtnObservationDerive = false;
                    isObservationDerive = true;
                    showLoadingObservation = true;
                    getAreas(true);
                  "
                  size="sm"
                  variant="info"
                  >Derivar
                </b-button>
              </b-col>
              <b-col align="right" cols="3" class="pr-2">
                <b-button
                  v-if="
                    observation.length > 1 &&
                    order.status < 3 &&
                    !isClicListDerive
                  "
                  :disabled="
                    isClicListDerive ||
                    (order.status == 2 && order.user_assigned_id == userId)
                  "
                  class="mt-3"
                  @click="area.id == 12 ? showCategoriesModal() : saveObservationAndTakeOrder()"
                  size="sm"
                  variant="info"
                  >Tomar
                </b-button>
              </b-col>
              <b-col align="right" cols="3" class="pr-2">
                <b-button
                  v-if="
                    observation.length > 1 &&
                    order.status == 2 &&
                    !isClicListDerive
                  "
                  :disabled="
                    isClicListDerive ||
                    (order.hasOwnProperty('user_assigned') &&
                      order.user_assigned_id != userId)
                  "
                  class="mt-3"
                  @click="$bvModal.show('confirm-modal')"
                  size="sm"
                  variant="info"
                  >Finalizar
                </b-button>
              </b-col>
              <b-col align="right" cols="3" class="pr-2">
                <b-button
                  v-if="
                    observation.length > 1 &&
                    !isClicListDerive
                  "
                  :disabled="
                    isClicListDerive ||
                    (order.hasOwnProperty('user_assigned') &&
                      order.user_assigned_id != userId)
                  "
                  class="mt-3"
                  @click="$bvModal.show('confirm-reject-modal')"
                  size="sm"
                  variant="danger"
                  >Rechazar
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <b-col align="right" cols="3" class="pr-2">
            <b-button
              v-if="observation.length > 1"
              class="mt-3"
              size="sm"
              @click="saveOrderObservation()"
              variant="primary"
              >Guardar
            </b-button>
          </b-col>
        </b-row>
        <hr />
        <b-overlay
          :show="showLoadingObservation"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="lg"
        >
          <b-row v-if="isObservationDerive">
            <b-col sm="12" md="12">
              <b-form-text for="select-state"> Área </b-form-text>
              <b-form-select
                size="sm"
                v-model="selectArea"
                :options="optionsAreas"
                @change="getUsersArea()"
              ></b-form-select>
            </b-col>
            <b-col v-if="this.area.id == this.selectArea" sm="12" md="12">
              <b-form-text for="select-state"> Usuario </b-form-text>
              <b-form-select
                size="sm"
                v-model="selectUser"
                :options="optionsUsers"
              ></b-form-select>
            </b-col>
            <b-col cols="12" align="center">
              <b-button
                class="mt-3"
                @click="
                  isBtnObservationDerive = true;
                  isObservationDerive = false;
                "
                variant="secondary"
                >Cancelar
              </b-button>
              <b-button
                :disabled="selectArea == 0"
                class="mt-3"
                @click="deriveOrder()"
                variant="primary"
                >Derivar
              </b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </b-overlay>
    </b-modal>
    <!-- MODAL Archivos -->
    <b-modal id="carousel-modal" ref="carousel-modal" size="md" hide-footer>
      <b-carousel
        id="carousel-1"
        style="text-shadow: 1px 1px 2px #333"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        :interval="0"
        :value="currentSlide"
      >
        <b-carousel-slide
          v-for="(file, index) in carouselFiles"
          :key="index"
          :img-src="
            isImage(file.extension)
              ? `https://api.ticketsflow.online/${getStoragePath(file.path)}`
              : 'img/files.png'
          "
        >
          <div v-if="!isImage(file.extension)" style="height: 100%">
            <b-button
              variant="info"
              size="sm"
              class="mr-2"
              @click="
                openPdf(
                  `https://api.ticketsflow.online/${getStoragePath(file.path)}`
                )
              "
            >
              Abrir archivo
            </b-button>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </b-modal>
    <!-- Msjs modal -->
    <b-modal id="msj-modal" ref="msj-modal" centered hide-footer hide-header>
      <div class="d-block text-center p-4">
        <h4>{{ msjModal }}</h4>
      </div>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        size="sm"
        @click="$bvModal.hide('msj-modal')"
        >OK</b-button
      >
    </b-modal>
    <!-- Confirm finalizar modal -->
    <b-modal
      id="confirm-modal"
      ref="confirm-modal"
      centered
      hide-footer
      hide-header
    >
      <b-overlay
        :show="showLoadingConfirm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="lg"
      >
        <div class="d-block text-center p-4">
          <h4>Confirmar finalización del Ticket</h4>
        </div>
        <b-row>
          <b-col cols="6" align="center">
            <b-button
              class="mt-3"
              variant="secondary"
              @click="$bvModal.hide('confirm-modal')"
              >Cancelar</b-button
            >
          </b-col>
          <b-col cols="6" align="center">
            <b-button
              class="mt-3"
              variant="success"
              @click="saveObservationAndFinishOrder()"
              >Confirmar</b-button
            >
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
    <!-- Confirmar verificación modal -->
    <b-modal
      id="confirm-verify-modal"
      ref="confirm-verify-modal"
      centered
      hide-footer
      hide-header
    >
      <b-overlay
        :show="showLoadingConfirm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="lg"
      >
        <div class="d-block text-center p-4">
          <h4>Confirmar Finalización y Verificación del Ticket</h4>
        </div>
        <b-row>
          <b-col cols="6" align="center">
            <b-button
              class="mt-3"
              variant="secondary"
              @click="$bvModal.hide('confirm-verify-modal')"
              >Cancelar</b-button
            >
          </b-col>
          <b-col cols="6" align="center">
            <b-button
              class="mt-3"
              variant="success"
              @click="saveVerifyAndFinishOrder()"
              >Confirmar</b-button
            >
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
      <!-- Confirmar Rechazo modal -->
    <b-modal
      id="confirm-reject-modal"
      ref="confirm-reject-modal"
      centered
      hide-footer
      hide-header
    >
      <b-overlay
        :show="showLoadingReject"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="lg"
      >
        <div class="d-block text-center p-4">
          <h4>Confirmar Rechazo del Ticket</h4>
        </div>
        <b-row>
          <b-col cols="6" align="center">
            <b-button
              class="mt-3"
              variant="secondary"
              @click="$bvModal.hide('confirm-reject-modal')"
              >Cancelar</b-button
            >
          </b-col>
          <b-col cols="6" align="center">
            <b-button
              class="mt-3"
              variant="success"
              @click="confirmRejectTicket()"
              >Confirmar</b-button
            >
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
      <!-- Seleccionar Tipo de Gasto modal -->
    <b-modal
      id="category-modal"
      ref="category-modal"
      centered
      hide-footer
      hide-header
    >
      <b-overlay
        :show="showLoadingCategory"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="lg"
      >
        <div class="d-block text-center p-4">
          <h4>Seleccione el Tipo de Gasto</h4>
        </div>
        <b-col>
          <b-input-group size="sm">
            <b-form-select
              size="sm"
              v-model="categorySelected"
              :options="categories"
            ></b-form-select>
          </b-input-group>
        </b-col>
        <b-row>
          <b-col cols="6" align="center">
            <b-button
              class="mt-3"
              variant="secondary"
              @click="$bvModal.hide('category-modal')"
              >Cancelar</b-button
            >
          </b-col>
          <b-col cols="6" align="center">
            <b-button
              class="mt-3"
              variant="success"
              :disabled="!categorySelected"
              @click="saveObservationAndTakeOrder()"
              >Confirmar</b-button
            >
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
// import Echo from 'laravel-echo';

export default {
  props: ["rol", "propOrderId", "orderData"],
  data() {
    return {
    showLoadingReject : false,
    showLoadingCategory : false,
      categories : [
        
      ],
      categorySelected : 0,
      showModal: false,
      filterObservation: null,
      showLoadingFilter: false,
      originAreaSelected: 0,
      isFilterObservation: false,
      isFinishWithOk: false,
      cantidadEnProceso: 0,
      cantidadFinalizado: 0,
      cantidadLeido: 0,
      cantodaEnPendiente: 0,
      cantidadRechazado: 0,

      vista: "Recibidos",
      isFilterStatusByUser: false, //Filtro por usuario
      filter: null,
      listStatusSelected: null,
      listStatus: [
        { text: "Todos", value: null },
        { text: "Pendientes", value: 0 },
        { text: "Leídos", value: 1 },
        { text: "En proceso", value: 2 },
        { text: "Finalizados", value: 3 },
      ],
      btnList: {
        received: ["btn-active", "", ""],
        finished: ["btn-active", "", ""],
        derivatives: ["btn-active", "", ""],
      },
      isBtnObservationDerive: true,
      isObservationDerive: false,
      isClicListDerive: false,
      isListReceived: false,
      history: [],
      historyAux: [],
      files: [],
      filesAccumulated: [],
      selectArea: 0,
      optionsAreas: [],
      selectUser: 0,
      optionsUsers: [],

      msjModal: null,
      observation: "",
      observationDerive: "",
      order: {
        id: null,
        order_status: null,
        area: null,
        description: null,
        files: [],
        observations: [],
      },
      isList: true,
      items: [],
      itemsReceived: [],
      itemsRealize: [],
      itemsDerive: [],
      fieldsRealized: [
        {
          key: "id",
          label: "#",
          thStyle: { width: "20px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "description",
          label: "DESCRIPCIÓN",
          thStyle: { width: "125px" },
          sortable: true,
          class: "text-left",
        },
        {
          key: "status_name",
          label: "ESTADO",
          thStyle: { width: "40px" },
          sortable: true,
          class: "text-left",
        },
        {
          key: "origin_area_name",
          label: "ÁREA ORIGEN",
          thStyle: { width: "55px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "name",
          label: "ÁREA DESTINO",
          thStyle: { width: "55px" },
          sortable: true,
          class: "text-center",
        },

        {
          key: "user_name",
          label: "USUARIO CREADOR",
          thStyle: { width: "65px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "created_at",
          label: "FECHA INICIO",
          thStyle: { width: "50px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "actions",
          label: "ACCIONES",
          thStyle: { width: "30px" },
          class: "text-center",
        },
      ],
      fieldsReceived: [],
      fieldsReceivedWithCategory: [
        {
          key: "id",
          label: "#",
          thStyle: { width: "20px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "description",
          label: "DESCRIPCIÓN",
          thStyle: { width: "125px" },
          sortable: true,
          class: "text-left",
        },
        {
          key: "status_name",
          label: "ESTADO",
          thStyle: { width: "40px" },
          sortable: true,
          class: "text-left",
        },
        {
          key: "origin_area_name",
          label: "ÁREA ORIGEN",
          thStyle: { width: "55px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "name",
          label: "ÁREA DESTINO",
          thStyle: { width: "55px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "user_name",
          label: "USUARIO CREADOR",
          thStyle: { width: "65px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "created_at",
          label: "FECHA INICIO",
          thStyle: { width: "50px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "categories",
          label: "CATEGORIA",
          thStyle: { width: "65px" },
          sortable: true,
          class: "text-center",
        },
        /*{
          key: "categories[0].cost",
          label: "COSTO",
          thStyle: { width: "50px" },
          sortable: true,
          class: "text-center",
        },*/
        {
          key: "actions",
          label: "ACCIONES",
          thStyle: { width: "30px" },
          class: "text-center",
        },
      ],
      fieldsReceivedWithOutCategory: [
        {
          key: "id",
          label: "#",
          thStyle: { width: "20px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "description",
          label: "DESCRIPCIÓN",
          thStyle: { width: "125px" },
          sortable: true,
          class: "text-left",
        },
        {
          key: "status_name",
          label: "ESTADO",
          thStyle: { width: "40px" },
          sortable: true,
          class: "text-left",
        },
        {
          key: "origin_area_name",
          label: "ÁREA ORIGEN",
          thStyle: { width: "55px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "name",
          label: "ÁREA DESTINO",
          thStyle: { width: "55px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "user_name",
          label: "USUARIO CREADOR",
          thStyle: { width: "65px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "created_at",
          label: "FECHA INICIO",
          thStyle: { width: "50px" },
          sortable: true,
          class: "text-center",
        },
        {
          key: "actions",
          label: "ACCIONES",
          thStyle: { width: "30px" },
          class: "text-center",
        },
      ],
      userId: null,
      showLoading: false,
      showLoadingDetail: false,
      showLoadingObservation: false,
      ticketDetails: null,
      itemsAux: {},

      optionsPaginado: [
        { value: 30, text: "30" },
        { value: 60, text: "60" },
        { value: 90, text: "90" },
        { value: -1, text: "Todos" },
      ],
      seletPaginado: 30,
      dataNotification: null,
      userWithoutArea: false,
      showLoadingModal: false,
      showLoadingConfirm: false,
      notification: null,
      currentSlide: 0,
      carouselFiles: [],
    };
  },
  watch: {
    "$store.state.notification": {
      handler(notification) {
        // Realizar acciones según la notificación en todas las vistas
        if (notification) {
          if (notification.hasOwnProperty("notification")) {
            this.notification = notification;
            //Verificamos si se está dentro del detalle del ticket
            if (this.notification.data.orderId == this.order.id) {
              //console.log(notification);
              let obs = JSON.parse(notification.data.observation);
              this.order.observations.push(obs);
              this.historyAux.push(obs);
            } else {
              //Verificamos si es creación o derivación, para agregar al listado
              if (
                this.notification.data.type == "0" ||
                this.notification.data.type == "5"
              ) {
                //Verificamos si viene de nuestra area
                if (this.area.id == Number(this.notification.data.areaId)) {
                  this.itemsReceived.unshift(
                    JSON.parse(this.notification.data.data)
                  );
                } else {
                  this.itemsRealize.unshift(
                    JSON.parse(this.notification.data.data)
                  );
                }
              }
              //Si es cambio de estado
              else if (
                Number(this.notification.data.status) > 0 &&
                Number(this.notification.data.status) != 4
              ) {
                const idOrder = this.notification.data.orderId;
                const statusOrder = this.notification.data.status;
                const newStatus = this.notification.data.newStatus;
                const nameArea = this.notification.data.areaName;
                const userName = this.notification.data.userName;

                this.itemsReceived.forEach((e) => {
                  if (Number(idOrder) === e.id) {
                    e.order_status = statusOrder;
                    e.status_name = newStatus;
                    e.userName = userName;
                    e.userNameDetail = userName;
                  }
                });

                this.itemsRealize.forEach((e) => {
                  if (Number(idOrder) === e.id) {
                    e.order_status = statusOrder;
                    e.status_name = newStatus;
                    e.name = nameArea;
                  }
                });
                // Ordenamos los listados, por status e id
                this.itemsReceived.sort((a, b) => {
                  if (a.order_status < b.order_status) return -1;
                  if (a.order_status > b.order_status) return 1;
                  if (a.id < b.id) return -1;
                  if (a.id > b.id) return 1;
                  return 0;
                });

                this.itemsRealize.sort((a, b) => {
                  if (a.order_status < b.order_status) return -1;
                  if (a.order_status > b.order_status) return 1;
                  if (a.id < b.id) return -1;
                  if (a.id > b.id) return 1;
                  return 0;
                });
              }
            }
          }
        }
        //his.$bvToast.show("notification-toast");
      },
      immediate: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    area() {
      return this.$store.getters.getArea;
    },
  },
  mounted() {
    //Verificamos si el usuario cuenta con un area asignada
    if (this.area != undefined) {
      this.getUser();
      this.getOrders();
      //Si viene por notificacion
      if (this.propOrderId) {
        const isTicketRecived = this.orderData.areaId == this.area.id;
        this.getDetailOrder(this.orderData, 0, isTicketRecived, false);
        //    getDetailOrder(item, index, isListReceived, isClicListDerive) {
      }
    } else {
      this.userWithoutArea = true;
    }
  },
  methods: {
    getCategoryField(categories){
      //temporalmente traigo el valor de categoria como array, siempre sera 1, osea el [0]
      const item = categories.length ? categories[0] : null;
      if(item){
        return `${item.description} - $${Intl.NumberFormat("es-AR").format(parseFloat(item.cost))}`;
      }
      return '';
    },
    getTicketsByObservation() {
      this.showLoading = true;
      this.$axios
        .get("/order/getTicketsByObservation", {
          params: {
            originArea: this.originAreaSelected,
            filterObservation: this.filterObservation,
            user_id: this.userId,
            area_id: this.area.id,
            cantidad_tikets: this.seletPaginado,
          },
        })
        .then((response) => {
          this.itemsAux.itemsReceived = [...response.data.orders_area];
          this.itemsAux.itemsRealize = [...response.data.orders_user];
          this.itemsAux.itemsDerive = [...response.data.orders_derive];
          this.itemsReceived = [...response.data.orders_area];
          this.itemsRealize = [...response.data.orders_user];
          this.itemsDerive = [...response.data.orders_derive];
          this.showLoading = false;
        });
    },
    confirmVerifyAndFinish() {
      if (this.isFinishWithOk) {
        this.$bvModal.show("confirm-verify-modal");
      }
    },
    updateView(notification) {
      // notificacion de lectura
      if (notification.data.type == 1) {
        if (this.searchAndUpdateOrder(notification.data, this.itemsRealize))
          return;
        if (this.searchAndUpdateOrder(notification.data, this.itemsReceived))
          return;
        if (this.searchAndUpdateOrder(notification.data, this.itemsDerive))
          return;
      }
    },
    searchAndUpdateOrder(data, items) {
      const indice = items.findIndex((order) => order.id == data.orderId);
      if (indice !== -1) {
        items[indice].status_name = data.newStatus;
        return true;
      } else {
        return false;
      }
    },
    /*makeToast(variant = null) {
      this.$bvToast.toast(this.noti.body, {
        title: this.noti.title,
        variant: variant,
        solid: true,
        noAutoHide: true,
      });
    },*/
    checkFile() {
      this.files.forEach((file, index) => {
        if (this.filesAccumulated.length >= 5) {
          this.msjModal = "Alcanzó la cantidad máxima de archivos permitidos";
          this.$refs["msj-modal"].show();
          return;
        }
        if (file.size > 3145728) {
          // 5mb
          this.msjModal = "El archivo excede el tamaño máximo permitido (3mb)";
          this.$refs["msj-modal"].show();
          this.files.splice(index, 1);
        } else {
          this.filesAccumulated.push(file);
          this.files = [];
        }
      });
    },
    reloadData() {
      this.getUser();
      //this.getArea();
      this.getOrders();
    },
    changeView(nuevaVista) {
      this.vista = nuevaVista;
      const elementosNav = document.querySelectorAll(".nav .active");
      elementosNav.forEach((elemento) => {
        elemento.classList.remove("active");
      });

      // Agregar la clase "active" al elemento correspondiente dentro de "#this.vista"
      const vistaActual = document.getElementById(this.vista);
      if (vistaActual) {
        const linkDentroDeVista = vistaActual.querySelector("a");
        if (linkDentroDeVista) {
          linkDentroDeVista.classList.add("active");
        }
      }

      if (this.vista == "Recibidos") {
        this.ShowCounter(this.itemsReceived);
      }
      if (this.vista == "Realizados") {
        this.ShowCounter(this.itemsRealize);
      }
      if (this.vista == "Derivados") {
        this.ShowCounter(this.itemsDerive);
      }
    },
    openFilterObservation() {
      this.isFilterObservation = !this.isFilterObservation;
    },
    ShowCounter(datos) {
      let Midatos = datos;
      this.ClearCounter();
      if (Midatos.length == 0) {
        this.ClearCounter();
        return;
      }
      const date = new Date();
      let day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate();
      let month =
        date.getMonth() + 1 < 9
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let currentDate = +day + "/" + month;
      Midatos.map((e) => {
        let datosFecha = e.updated_at.split("/");

        datosFecha = datosFecha[0] + "/" + datosFecha[1];

        if (datosFecha == currentDate) {
          if (e.order_status == 0) {
            this.cantodaEnPendiente++;
          }
          if (e.order_status == 1) {
            this.cantidadLeido++;
          }
          if (e.order_status == 2) {
            this.cantidadEnProceso++;
          }
          if (e.order_status == 3) {
            this.cantidadFinalizado++;
          }
          if (e.order_status == 4) {
            this.cantidadRechazado++;
          }
        }
      });
    },
    ClearCounter() {
      this.cantodaEnPendiente = 0;
      this.cantidadLeido = 0;
      this.cantidadEnProceso = 0;
      this.cantidadFinalizado = 0;
      this.cantidadRechazado = 0;
    },
    checkLenght() {
      if (this.observation.length == 300) {
        this.msjModal = "Alcanzó la cantidad máxima de caracteres";
        this.$refs["msj-modal"].show();
      }
    },
    setList() {
      this.itemsRealize = this.itemsAux.itemsRealize;
      this.itemsDerive = this.itemsAux.itemsDerive;
      this.itemsReceived = this.itemsAux.itemsReceived;

      if (this.isFilterStatusByUser) {
        if (this.listStatusSelected != null) {
          this.itemsRealize = this.itemsRealize.filter(
            (e) =>
              e.order_status == this.listStatusSelected &&
              e.user_id == this.userId
          );
          this.itemsDerive = this.itemsDerive.filter(
            (e) =>
              e.order_status == this.listStatusSelected &&
              e.user_id == this.userId
          );
          this.itemsReceived = this.itemsReceived.filter(
            (e) =>
              e.order_status == this.listStatusSelected &&
              e.hasOwnProperty("user_name_detail_id") &&
              e.user_name_detail_id == this.userId
          );
        }
        this.getAllTicketsUser();
      } else {
        if (this.listStatusSelected != null) {
          this.itemsRealize = this.itemsRealize.filter(
            (e) => e.order_status == this.listStatusSelected
          );
          this.itemsDerive = this.itemsDerive.filter(
            (e) => e.order_status == this.listStatusSelected
          );
          this.itemsReceived = this.itemsReceived.filter(
            (e) => e.order_status == this.listStatusSelected
          );
        }
      }
      if (this.area.non_resolving) {
        this.vista = "Realizados";
        const elementosNav = document.querySelectorAll(".nav .active");
        elementosNav.forEach((elemento) => {
          elemento.classList.remove("active");
        });

        // Agregar la clase "active" al elemento correspondiente dentro de "#this.vista"
        const vistaActual = document.getElementById(this.vista);
        if (vistaActual) {
          const linkDentroDeVista = vistaActual.querySelector("a");
          if (linkDentroDeVista) {
            linkDentroDeVista.classList.add("active");
          }
        }
      }
    },
    getAllTicketsUser() {
      this.itemsRealize = this.itemsRealize.filter(
        (e) => e.user_id == this.userId
      );
      this.itemsDerive = this.itemsDerive.filter(
        (e) => e.user_id == this.userId
      );
      this.itemsReceived = this.itemsReceived.filter(
        (e) =>
          e.hasOwnProperty("user_name_detail_id") &&
          e.user_name_detail_id == this.userId
      );
    },
    openFile(file) {
      const baseURL = this.$axios.defaults.baseURL;
      const baseURLWithoutApi = baseURL.replace("/api", "");
      window.open(
        "https://api.ticketsflow.online/" + this.getStoragePath(file.path),
        "_blank"
      );
    },
    getStoragePath(path) {
      return path.replace("public", "storage");
    },
    isImage(extension) {
      const imagesExtension = {
        jpg: true,
        jpge: true,
        png: true,
        webp: true,
      };
      if (imagesExtension[extension]) {
        return true;
      }
      return false;
    },
    updateRecords() {
      /*this.isFilterStatusByUser = false;
      this.listStatusSelected = null;*/
      if (this.isList) {
        // Actualizar listado
        this.getOrders();
        this.btnList = {
          received: ["btn-active", "", ""],
          finished: ["btn-active", "", ""],
          derivatives: ["btn-active", "", ""],
        };
      } else {
        //Actualizar detalle
        this.showLoadingDetail = true;
        this.getDetailOrder(
          this.ticketDetails.item,
          this.ticketDetails.index,
          this.ticketDetails.isListReceived,
          this.ticketDetails.isClicListDerive
        );
        //this.showLoadingDetail = false;
      }
    },
    openDeriveModal() {
      this.getAreas();
    },
    getOrders() {
      this.fieldsReceived = this.fieldsReceivedWithOutCategory;
      this.showLoading = true;
      this.$axios
        .get(`/order/getOrders`, {
          params: {
            user_id: this.userId,
            area_id: this.area.id,
            cantidad_tikets: this.seletPaginado,
          },
        })
        .then((response) => {
          //this.ShowCounter(response.data.orders_area);
          this.itemsAux.itemsReceived = [...response.data.orders_area];
          this.itemsAux.itemsRealize = [...response.data.orders_user];
          this.itemsAux.itemsDerive = [...response.data.orders_derive];

          this.itemsReceived = [...response.data.orders_area];
          this.itemsRealize = [...response.data.orders_user];
          this.itemsDerive = [...response.data.orders_derive];
          //this.setOrders(response.data);
          this.showLoading = false;
          this.setList();
          this.isFilterObservation = false;
          this.filterObservation = null;
          this.originAreaSelected = 0;
          //Cambiar de cabecera si se trata del jefe de mantenimiento
          if(this.area.id == 12 && this.user.area_user.level == 'jefe'){
            this.fieldsReceived = this.fieldsReceivedWithCategory;
          }
        });
    },
    setOrders(orders) {
      const orderList = orders.orders_area
        .concat(orders.orders_user)
        .filter((e, i, a) => a.findIndex((e2) => e2.id === e.id) === i);
      this.items = orderList;
    },
    getDetailOrder(item, index, isListReceived, isClicListDerive) {
      const ticketDetails = {
        item: item,
        index: index,
        isListReceived: isListReceived,
        isClicListDerive: isClicListDerive,
      };
      //Guardamos los datos del detalle del ticket para actualizar
      this.ticketDetails = ticketDetails;
      this.observation = "";
      this.isClicListDerive = isClicListDerive;
      this.isListReceived = isListReceived;
      let isUserCreator = 0;
      this.showLoading = true;
      this.showLoadingDetail = true;
      if (item.user_id == this.userId) {
        isUserCreator = 1;
      }
      this.$axios
        .get("/order/getDetailOrder", {
          params: {
            user_id: this.userId,
            order_id: item.id,
            is_user_creator: isUserCreator,
            area_id: item.area_id,
            is_click_list_derive: isClicListDerive,
            is_click_list_received: isListReceived,
          },
        })
        .then((response) => {
          this.order = response.data.order;
          this.history = response.data.history_order;
          this.order.area = item.name;
          this.order.origin_area_name = item.origin_area_name;
          this.order.user = item.user_name;
          this.order.index = index;
          this.isList = false;
          this.joinHistoryWithObservations(
            this.history,
            this.order.observations
          );
          this.showLoading = false;
          this.showLoadingDetail = false;
        });
    },
    getDateValue(date) {
      const [day, month, year] = date.split("-");
      const [years, hours, x] = year.split(" ");
      //onsole.log(years + "-" + x + "-" + hours);
      const date2 = new Date(years + "-" + month + "-" + day + " " + hours);
      return date2;
    },
    joinHistoryWithObservations(history, observations) {
      this.historyAux = [...history];
      if (history.length == 1) {
        observations.forEach((e) => {
          this.historyAux.push(e);
        });
        return;
      }
      for (let i = 0; i < history.length; i++) {
        if (i < history.length - 1) {
          observations.forEach((e, y) => {
            if (
              this.getDateValue(history[i].created_at) <=
                this.getDateValue(e.created_at) &&
              this.getDateValue(history[i + 1].created_at) >
                this.getDateValue(e.created_at)
            ) {
              this.historyAux.splice(i + y + 1, 0, e);
            }
          });
        }
        if (i == history.length - 1) {
          observations.forEach((e, y) => {
            if (
              this.getDateValue(history[i].created_at) <=
              this.getDateValue(e.created_at)
            ) {
              this.historyAux.splice(i + y + 1, 0, e);
            }
          });
        }
      }
      //console.log(this.historyAux);
    },
    getStatus(status, variant = 0) {
      const statusOrder = {
        0: "pendiente",
        1: "leído",
        2: "en Proceso",
        3: "finalizado",
        4: "rechazado",
        6: "finalizado ok",
      };
      if (variant == 1) {
        const statusVariant = {
          0: "danger",
          1: "secondary",
          2: "info",
          3: "success",
          4: "danger",
        };
        return statusVariant[status];
      }
      return statusOrder[status];
    },
    saveOrderObservation() {
      this.showLoadingModal = true;
      let formData = new FormData();
      formData.append("user_id", this.userId);
      formData.append("order_id", this.order.id);
      formData.append("observation", this.observation);

      for (var i = 0; i < this.filesAccumulated.length; i++) {
        let file = this.filesAccumulated[i];
        formData.append("files[" + i + "]", file);
      }
      this.$axios
        .post("/order/saveObservation", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const obs = response.data;
          //obs.files = [];
          this.order.observations.push(obs);
          this.msjModal = "Observación agregada!";
          this.$refs["msj-modal"].show();
          this.$bvModal.hide("modal-observation");
          this.historyAux.push(obs);
          this.observation = "";
          this.showLoadingModal = false;
          this.filesAccumulated = [];
        });
    },
    newObservation() {
      this.$bvModal.show("modal-observation");
    },
    deriveOrder() {
      let formData = new FormData();
      formData.append("user_id", this.userId);
      formData.append("order_id", this.order.id);
      formData.append("area_id", this.selectArea);
      formData.append(
        "user_derive_id",
        this.selectUser != 0 ? this.selectUser : 0
      );
      formData.append("observation", this.observation);
      for (var i = 0; i < this.filesAccumulated.length; i++) {
        let file = this.filesAccumulated[i];
        formData.append("files[" + i + "]", file);
      }
      this.$axios
        .post("/order/deriveOrder", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (this.showLoadingObservation) {
            this.showLoadingObservation = false;
            this.isClicListDerive = false;
          }
          this.msjModal = "Ticket derivado!";
          if (response.data.value != 0) {
            this.msjModal = "Asignación realizada!";
          }
          this.observation = "";
          this.changeAreaOrder(response.data.value);
          this.updateRecords();
        });
    },
    changeAreaOrder(type) {
      this.isList = true;
      this.isBtnObservationDerive = true;
      this.isObservationDerive = false;
      this.$refs["msj-modal"].show();
      this.$bvModal.hide("modal-observation");
      this.clearDeriveModal();
    },
    showCategoriesModal(){
      this.showLoadingModal = true;
      this.$axios
        .get("/order/getCategories")
        .then((response) => {
          this.categories = response.data.map(category => {
            category.text = `${category.value} - ${category.text} -  $${category.cost ? Intl.NumberFormat("es-AR").format(parseFloat(category.cost)) : 'no definido'}`;
            category.value = category.value;
            return category;
          });
          this.categories.unshift({ text: "Seleccione el tipo de Gasto...", value: 0 });
          this.showLoadingModal = false;
          this.$bvModal.show("category-modal");
        });
        return;
    },
    saveObservationAndTakeOrder() {
      this.showLoadingModal = true;
      this.showLoadingCategory = true;
      let formData = new FormData();
      formData.append("user_id", this.userId);
      formData.append("order_id", this.order.id);
      formData.append("area_id", this.order.area_id);
      formData.append("observation", this.observation);
      formData.append("observation", this.observation);
      formData.append("categoryId", this.area.id == 12 ? this.categorySelected : 0);//Verificamos el valor de CATEGORIA si fuera area mantenimiento

      for (var i = 0; i < this.filesAccumulated.length; i++) {
        let file = this.filesAccumulated[i];
        formData.append("files[" + i + "]", file);
      }
      this.$axios
        .post("/order/saveObservationAndTakeOrder", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.msjModal = "Tomaste el ticket. Se encuentra en proceso!";
          this.$refs["msj-modal"].show();
          // this.isList = true;
          this.$bvModal.hide("modal-observation");
          this.$bvModal.hide("category-modal");
          if (this.isListReceived) {
            this.itemsReceived[this.order.index].order_status = 2;
            this.itemsReceived[this.order.index].status_name =
              this.getStatus(2);
          } else {
            this.itemsRealize[this.order.index].order_status = 2;
            this.itemsRealize[this.order.index].status_name = this.getStatus(2);
          }
          this.updateRecords();
          this.observation = "";
          this.filesAccumulated = [];
          this.showLoadingModal = false;
          this.showLoadingConfirm = false;
          this.showLoadingCategory = false;
        });
    },
    takeOrder() {
      this.$axios
        .post("/order/takeOrder", {
          user_id: this.userId,
          order_id: this.order.id,
          area_id: this.order.area_id,
        })
        .then((response) => {
          this.msjModal = "Tomaste el ticket. Se encuentra en proceso!";
          this.$refs["msj-modal"].show();
          this.isList = true;
          if (this.isListReceived) {
            this.itemsReceived[this.order.index].order_status = 2;
            this.itemsReceived[this.order.index].status_name =
              this.getStatus(2);
          } else {
            this.itemsRealize[this.order.index].order_status = 2;
            this.itemsRealize[this.order.index].status_name = this.getStatus(2);
          }
          //this.changeAreaOrder();
          //this.$bvModal.hide('modal-observation');
        });
    },
    saveVerifyAndFinishOrder() {
      this.showLoadingConfirm = true;

      this.$axios
        .post("/order/saveVerifyAndFinishOrder", {
          user_id: this.userId,
          order_id: this.order.id,
          area_id: this.order.area_id,
        })
        .then((response) => {
          this.msjModal = response.data;
          this.$refs["msj-modal"].show();
          this.isList = true;
          this.isFinishWithOk = false;
          this.$bvModal.hide("modal-observation");
          this.$bvModal.hide("confirm-verify-modal");
          this.updateRecords();
          this.showLoadingConfirm = false;
        });
    },
    confirmRejectTicket(){
      this.showLoadingReject = true;

      this.$axios
        .post("/order/confirmRejectTicket", {
          user_id: this.userId,
          order_id: this.order.id,
          area_id: this.order.area_id,
          observation: this.observation
        })
        .then((response) => {
          this.msjModal = response.data;
          this.$refs["msj-modal"].show();
          this.isList = true;
          this.isFinishWithOk = false;
          this.$bvModal.hide("modal-observation");
          this.$bvModal.hide("confirm-reject-modal");
          this.updateRecords();
          this.showLoadingReject = false;
        });
    },
    saveObservationAndFinishOrder() {
      this.showLoadingConfirm = true;
      let formData = new FormData();
      formData.append("user_id", this.userId);
      formData.append("order_id", this.order.id);
      formData.append("area_id", this.order.area_id);
      formData.append("observation", this.observation);

      for (var i = 0; i < this.filesAccumulated.length; i++) {
        let file = this.filesAccumulated[i];
        formData.append("files[" + i + "]", file);
      }
      this.$axios
        .post("/order/saveObservationAndFinishOrder", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.msjModal = "Ticket Finalizado!";
          this.$refs["msj-modal"].show();
          this.isList = true;
          this.$bvModal.hide("modal-observation");
          this.$bvModal.hide("confirm-modal");

          if (this.isListReceived) {
            this.itemsReceived[this.order.index].order_status = 3;
            this.itemsReceived[this.order.index].status_name =
              this.getStatus(3);
          } else {
            this.itemsRealize[this.order.index].order_status = 3;
            this.itemsRealize[this.order.index].status_name = this.getStatus(3);
          }
          this.updateRecords();
          this.observation = "";
          this.filesAccumulated = [];
          this.showLoadingConfirm = false;
        });
    },
    finishOrder() {
      this.$axios
        .post("/order/finishOrder", {
          user_id: this.userId,
          order_id: this.order.id,
          area_id: this.order.area_id,
        })
        .then((response) => {
          this.msjModal = "Ticket Finalizado!";
          this.$refs["msj-modal"].show();
          this.isList = true;
          if (this.isListReceived) {
            this.itemsReceived[this.order.index].order_status = 3;
            this.itemsReceived[this.order.index].status_name =
              this.getStatus(3);
          } else {
            this.itemsRealize[this.order.index].order_status = 3;
            this.itemsRealize[this.order.index].status_name = this.getStatus(3);
          }
          //this.changeAreaOrder();
          //this.$bvModal.hide('modal-observation');
        });
    },
    setButtonActivated(btnList, btnIndex) {
      for (let i = 0; i < this.btnList[btnList].length; i++) {
        if (btnIndex == i) {
          this.btnList[btnList][i] = "btn-active";
        } else {
          this.btnList[btnList][i] = "";
        }
      }
    },
    getOrdersByFilters(isOrderArea, isOrdersFinished, btnIndex) {
      //Para colorear el btn presionado
      const btnList = {
        1: "received",
        0: "finished",
        2: "derivatives",
      };
      this.setButtonActivated(btnList[isOrderArea], btnIndex);
      this.showLoading = true;
      this.$axios
        .get("/order/getOrdersByFilters", {
          params: {
            user_id: this.userId,
            is_orders_area: isOrderArea,
            is_orders_finished: isOrdersFinished,
            area_id: this.area.id,
          },
        })
        .then((response) => {
          if (isOrderArea == 1) {
            this.itemsReceived = [...response.data];
            this.showLoading = false;
            return;
          }
          if (isOrderArea == 2) {
            this.itemsDerive = [...response.data];
            this.showLoading = false;
            return;
          }
          this.itemsRealize = [...response.data];
          this.showLoading = false;
        });
    },
    clearDeriveModal() {
      this.selectArea = 0;
      this.selectUser = 0;
      this.observationDerive = "";
    },
    getUser() {
      this.userId = this.user.id;
    },
    getUsersArea() {
      if (this.area.id != this.selectArea) {
        return;
      }
      this.$axios
        .get("/admin/areas/getUsersArea", {
          params: {
            user_id: this.userId,
            area_id: this.selectArea,
          },
        })
        .then((response) => {
          const defaultValue = {
            value: 0,
            text: "Seleccione un Usuario",
          };
          response.data.splice(0, 0, defaultValue);
          this.optionsUsers = response.data;
        });
    },
    getOriginAreas() {
      this.showLoadingFilter = true;
      this.$axios.get("/admin/areas/getAreas").then((response) => {
        let areas = response.data.areas;
        this.isFilterObservation = !this.isFilterObservation;

        this.showLoadingFilter = false;
        this.formatOptionsSelectAreas(areas);
      });
    },
    getAreas(isModalObservation = false) {
      this.showLoadingDetail = true;
      this.$axios
        .get("/admin/areas/getAreas", {
          params: {
            area_id: this.area.id,
          },
        })
        .then((response) => {
          let areas = response.data.areas;
          if (isModalObservation) {
          } else {
            this.$bvModal.show("modal-derive");
          }
          this.showLoadingObservation = false;
          this.showLoadingDetail = false;
          this.formatOptionsSelectAreas(areas);
        });
    },
    sortDatesMainTable(a, b, key) {
      if (key === "created_at") {
        a = this.convertToCompareDate(a[key]);
        b = this.convertToCompareDate(b[key]);
        return b - a;
      } else {
        // Let b-table handle sorting other fields (other than `date` field)
        return false;
      }
    },
    convertToCompareDate(item) {
      const [day, month, year] = item.split("/");
      const [years, hours, x] = year.split(" ");
      const date = new Date(
        "20" + years + "-" + month + "-" + day + " " + hours
      );
      return date;
    },
    formatOptionsSelectAreas(areas) {
      let arrayAux = [];
      arrayAux = [{ text: "Seleccione un Área...", value: 0 }];

      areas.forEach((e) => {
        arrayAux.push({
          value: e.id,
          text: e.name + " - " + e.description,
        });
      });
      this.optionsAreas = arrayAux;
      this.selectDeposit = 0;
    },
    goToNewOrder() {
      window.location.href = "/tickets/new";
    },
    showCarousel(index, files) {
      this.carouselFiles = files;
      this.currentSlide = index;
      this.$refs["carousel-modal"].show();
      this.$bvModal.show("carousel-modal");
    },

    openPdf(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style >
.icon-detail {
  color: #4fc2f0;
  font-size: 13pt;
  padding: 4pt 3pt 3pt 3pt;
}
.image-container {
  width: 60pt;
  height: 40pt; /* Puedes ajustar la altura según tus necesidades */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image {
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.filter-observation {
  background-color: #deedee;
}
.table-rotation {
  max-height: 100%;
}

.img-preview {
  color: #33a9d7;
  font-size: 40pt;
}

.subtitle {
  background-color: #229be0;
  color: white;
  margin-bottom: 3pt;
}

.btn-subtitle {
  background-color: white !important;
  color: rgb(13, 139, 182);
}

.files {
  margin-top: 8pt;
  background-color: rgb(234, 234, 234);
  border-style: solid;
  border-radius: 15px;
  border-color: rgb(234, 234, 234);
}
.files-detail {
  margin: 2pt 0pt 2pt 0pt;
  padding-left: 20pt !important;
  padding-right: 20pt !important;
}
.observations {
  font-size: 9pt;
  margin-top: 8pt;
  background-color: rgb(234, 234, 234);
  border-style: solid;
  border-radius: 15px;
  border-color: rgb(234, 234, 234);
}

.txt-info {
  font-size: 10pt;
  background-color: rgb(248, 248, 248);
}

.row {
  border-style: none;
}

.user-observation {
  color: #5a7ab4;
  font-weight: bold;
}

.history-item div {
  background-color: rgb(247, 247, 247);
}

.btn-active {
  background-color: rgb(221, 247, 255) !important;
  color: black;
}

.contador {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.contador small {
  border-radius: 10px;
  padding: 5px;
}
.col {
  font-size: 10pt;
}
</style>
