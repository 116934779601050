<template>
  <div class="t-container">
    <b-col cols="9">
      <b-row>
        <b-col class="toast-title" cols="12">{{ noti.title }}</b-col>
        <b-col cols="12">{{ noti.body }}</b-col>
      </b-row>
    </b-col>

    <b-col cols="3" class="pl-0">
      <b-button size="sm" variant="primary" @click.stop="clicked"
        >ABRIR</b-button
      >
    </b-col>
  </div>
</template>
<script>
import router from "../router";

export default {
  props: ["data", "noti", "currentPath", "toastId"],
  methods: {
    clicked() {
      //console.log('data',this.data);
      //console.log('noti',this.noti);
      // Emit a "click" event when clicked.
      // Can be any event though and even pass parameters back
      this.$emit("click");
      this.data.id = this.data.orderId;
      this.data.area_id = this.data.areaId;
      this.data.name = this.data.areaName;
      this.data.user_name = this.data.userName;
      const propsData = {
        propOrderId: this.data.orderId,
        orderData: this.data,
      };
      const tempRoute = { name: "temp", params: { dummyParam: "temp" } };

      if (this.currentPath === "/tickets") {
        router.push(tempRoute).then(() => {
          router.replace({
            name: "tickets",
            params: propsData,
          });
        });
      } else {
        router.push({
          name: "tickets",
          params: propsData,
        });
      }
      this.$emit("close-toast", this.toastId); // Emitir evento para cerrar el Toast
    },
    redirectToView() {
      this.data.id = this.data.orderId;
      this.data.area_id = this.data.areaId;
      this.data.name = this.data.areaName;
      this.data.user_name = this.data.userName;
      const propsData = {
        propOrderId: this.data.orderId,
        orderData: this.data,
      };
      this.$router.push({
        name: "tickets",
        params: propsData,
      });
    },
  },
};
</script>
<style>
.t-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action {
  height: 40px;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  border: thin solid currentColor;
}
.t-container {
  font-size: 10pt;
}
.Vue-Toastification__toast {
  max-width: 340px !important;
  min-width: 300px !important;
  padding: 5pt 10pt 5pt 10pt !important;
  background-color: #39a5c0e3 !important;
}
.toast-title {
  font-weight: bold;
}
</style>
