<template>
  <div align="center" class="login-container">
    <b-overlay :show="show" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
          <p id="cancel-label">Iniciado sesion...</p>
          <b-button
            ref="Cancelar"
            variant="outline-danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="show = false"
          >
            Cancelar
          </b-button>
        </div>
      </template>
      <b-container
        align="center"
        class="justify-content-center align-items-center h-100"
      >
        <b-col md="6" lg="4">
          <b-card>
            <b-card-title class="text-center">
              <b-img
                src="/img/logo-tickets.png"
                height="35"
                width="35"
                alt="Logo"
                fluid
              ></b-img>
              Tickets Flow
            </b-card-title>
            <b-form @submit.prevent="login">
              <b-col v-if="username" align="left" class="p-0 label-from">
                <label for="user">Usuario</label>
              </b-col>
              <b-form-group id="user-group" label-for="user">
                <b-form-input
                  placeholder="Usuario"
                  id="user"
                  v-model="username"
                  required
                ></b-form-input>
              </b-form-group>
              <b-col v-if="password" align="left" class="p-0 label-from">
                <label for="user">Contraseña</label>
              </b-col>
              <b-form-group id="password-group" label-for="password">
                <b-input-group>
                  <b-form-input
                    placeholder="Contraseña"
                    id="password"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    required
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      @click="togglePassword()"
                      variant="outline-secondary"
                    >
                      <i
                        :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                      ></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-button type="submit" variant="primary" block>
                Ingresar
              </b-button>
            </b-form>
          </b-card>
        </b-col>
      </b-container>
      <b-col v-if="msg" cols="12" class="msg-error">
        {{ msg }}
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
      show: false,

      msg: null,
    };
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    async login() {
      this.msg = null;
      this.show = true;
      this.$store
        .dispatch("login", {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          //console.log(res);
          if (res.status == 200) {
            this.$router.push("/").catch(() => {});
          }
          this.show = false;
        })
        .catch((err) => {
          //console.log(err);
          if(err.response.status == 401){
            this.msg = 'Usuario o Contraseña incorrecta'
          }else{
            this.msg = err.response.data.msg;
          }
          this.show = false;
        });
    },
  },
  updateP(user) {
    this.clearPermissions();
    user.roles[0].permissions.forEach((permission) => {
      const permissionName = permission.name;
      this.updatePermission({ permission: permissionName, status: true });
    });
  },
};
</script>

<style scoped>
.login-container {
  padding-top: 60pt;
}
.msg-error {
  color: red;
  font-weight: bold;
}
.label-from{
  font-size: 9pt;
}
</style>
