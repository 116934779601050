// auth.js
import axios from 'axios';

const API_URL = 'http://tickets.test/api';

export default {
  login(credentials) {
    return axios.post(`${API_URL}/login`, credentials);
  },
  logout() {
    // Implementar lógica de cierre de sesión
  },
  async checkAuthentication() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }

    const decodedToken = parseJwt(token);

    // Verificar si el token ha expirado
    if (decodedToken.exp < Date.now() / 1000) {
      // Token expirado, intentar refrescarlo
      //console.log('token expirado');
      try {
        const response = await axios.post(`${API_URL}/refresh`, {
          refresh_token: localStorage.getItem('refresh_token'),
        });

        // Almacena el nuevo token y actualiza la expiración
        //localStorage.setItem('token', response.data.token);
        localStorage.setItem('refresh_token', response.data.token);

        return true; // Autenticación exitosa
      } catch (error) {
        console.error('Error al refrescar el token:', error);
        return false; // Fallo en la autenticación
      }
    } else {
      return true; // Token válido
    }
  },
};

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
}
