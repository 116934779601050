<template>
  <div class="container">
    <main-template :titulo="'Sesiones'">
      <b-col cols="12" class="p-0">
        <b-row>
          <b-col cols="4">
            <b-input-group size="sm" class="mb-2">
              <b-input-group-prepend is-text>
                <b-icon icon="search"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                v-model="filter"
                size="sm"
                type="search"
                placeholder="Search terms"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col align="right" cols="8">
            <b-button
              @click="getUserSessions"
              size="sm"
              title="Agregar rol"
              variant="outline-primary"
            >
              <b-icon icon="arrow-clockwise"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </b-col>

      <custom-table
        :items="items"
        :show="showLoading"
        :pagination="true"
        :perPage="perPage"
        :currentPage="currentPage"
        :fields="fields"
        :keys="['actions']"
        :filter="filter"
      >

        <template #actions="{ item }">
          
          <b-icon

            @click="removeUserSession(item)"
            icon="trash-fill"
            class="h6 m-0 pointer"
            variant="danger"
          ></b-icon>
          

        </template>
      </custom-table>

      
    </main-template>

  </div>
</template>

<script>
import MainTemplate from "@/components/MainTemplate.vue";
import CustomTable from "@/components/tables/CustomTable.vue";

export default {
  components: { MainTemplate, CustomTable },
  data() {
    return {
      isEdit: false,
      showLoading: false,
      showLoadingModal: false,
      items: [],
      fields: [
        { key: "id", label: "#", thStyle: { width: "30px" }, sortable: true },
        {
          key: "user.name",
          label: "NOMBRE",
          thStyle: { width: "70px" },
          sortable: true,
        },
        {
          key: "user.email",
          label: "EMAIL",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "created_at",
          label: "FECHA",
          thStyle: { width: "65px" },
          tdClass: ["text-right"],
          thClass: ["text-right"],
          sortable: true,
        },
        {
          key: "role",
          label: "ROL",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "last_activity",
          label: "ULTIMA ACTIVIDAD",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { width: "45px" },
          tdClass: ["text-center"],
          thClass: ["text-center"],
        },
      ],

      perPage: 20,
      currentPage: 1,
      filter: null,

      modalVisible: false,
      user: {
        id: null,
        name: "",
        rol: null,
        email: "",
        password: "",
      },
      roles: [],
    };
  },
  mounted() {
    this.getUserSessions();
  },
  methods: {
    removeUserSession(item){
      this.showLoading = true;

      this.$axios
        .delete("/admin/deleteSession",{
          params: {
            session_id : item.id
          }
        })
        .then((response) => {
          this.showLoading = false;
          this.getUserSessions();
        })
        .catch((err) => {
          this.showLoading = false;
          //this.msjModal = "Error! " + err;
          //this.$refs["msj-modal"].show();
        });
    },
    getUserSessions() {
      this.showLoading = true;

      this.$axios
        .get("/admin/getUserSessions")
        .then((response) => {
          this.items = [...response.data];
          this.showLoading = false;
        })
        .catch((err) => {
          this.showLoading = false;
          //this.msjModal = "Error! " + err;
          //this.$refs["msj-modal"].show();
        });
    },
    getDetails() {},
    showModal() {
      this.showLoadingModal = true;
      this.modalVisible = true;

      this.$axios
        .get("/admin/getRoles")
        .then((response) => {
          const rolesOptions = response.data.map((item) => ({
            value: item.id,
            text: item.name,
          }));

          this.roles = rolesOptions;
          this.showLoadingModal = false;
        })
        .catch((err) => {
          this.showLoadingModal = false;
          //this.msjModal = "Error! " + err;
          //this.$refs["msj-modal"].show();
        });
    },
    saveUsuario() {
      if(this.isEdit){
        this.updateUser();
      }else{
        this.createUser();
      }
    },

    clearForm(){
        this.name = null;
        this.rol = null;
        this.password = null;
        this.email = null;
    }
  },
};
</script>
<style scoped>
</style>