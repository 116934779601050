<template>
  <div class="container">
    <main-template :titulo="'Usuarios'">
      <b-col cols="12" class="p-0">
        <b-row>
          <b-col cols="4">
            <b-input-group size="sm" class="mb-2">
              <b-input-group-prepend is-text>
                <b-icon icon="search"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                v-model="filter"
                size="sm"
                type="search"
                placeholder="Search terms"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col align="right" cols="8">
            <b-button
              @click="
                clearForm();
                showModal();
              "
              size="sm"
              title="Agregar usuario"
              variant="outline-primary"
            >
              <b-icon icon="plus-lg"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </b-col>

      <custom-table
        :items="items"
        :show="showLoading"
        :pagination="true"
        :perPage="perPage"
        :currentPage="currentPage"
        :fields="fields"
        :keys="['actions', 'roles']"
        :filter="filter"
      >
        <template #roles="{ item }">
          <template v-for="(rol, index) in item.roles">
            <b-badge :key="index" variant="info">{{ rol.name }}</b-badge>
          </template>
        </template>
        <template #actions="{ item }">
          <!--b-icon
            @click="getDetails(item)"
            icon="eye-fill"
            class="h6 m-0 pointer"
            variant="info"
          ></b-icon-->
          <b-icon
            @click="editUser(item)"
            icon="pencil-fill"
            class="h6 m-0 pointer"
            variant="warning"
          ></b-icon>
        </template>
      </custom-table>
    </main-template>
    <!-- MODAL CREAR/EDITAR USUARIO -->
    <b-modal
      v-model="modalVisible"
      :title="`${isEdit ? 'Editar' : 'Crear'} Usuario`"
      size="md"
      hide-footer
    >
      <b-overlay rounded="xl" :show="showLoadingModal">
        <b-container fluid>
          <b-form autocomplete="off">
            <b-row>
              <b-col sm="6">
                <b-form-group label="Nombre:">
                  <b-form-input
                    size="sm"
                    v-model="user.name"
                    placeholder="Ingrese su nombre"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Rol:">
                  <b-form-select
                    size="sm"
                    v-model="user.rol"
                    :options="roles"
                    required
                  ></b-form-select>
                </b-form-group>
                <b-form-group label="Area:">
                  <b-form-select
                    size="sm"
                    v-model="user.area"
                    :options="areas"
                    required
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Email:">
                  <b-form-input
                    size="sm"
                    id="emailform"
                    v-model="user.email"
                    type="email"
                    placeholder="Ingrese su email"
                    required
                    autocomplete="nope"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Contraseña:">
                  <b-form-input
                    size="sm"
                    id="passform"
                    v-model="user.password"
                    type="password"
                    placeholder="Ingrese su contraseña"
                    required
                    autocomplete="new-password"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
          <b-row class="mt-3">
            <b-col>
              <b-button size="sm" @click="modalVisible = false" variant="danger"
                >CANCELAR</b-button
              >
            </b-col>
            <b-col align="right">
              <b-button size="sm" @click="saveUsuario()" variant="primary">{{
                isEdit ? "EDITAR" : "CREAR"
              }}</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-modal>
    <!-- Msjs modal -->
    <b-modal id="msj-modal" ref="msj-modal" centered hide-footer hide-header>
      <div class="d-block text-center p-4">
        <h4>{{ msjModal }}</h4>
      </div>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        size="sm"
        @click="$bvModal.hide('msj-modal')"
        >OK</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import MainTemplate from "@/components/MainTemplate.vue";
import CustomTable from "@/components/tables/CustomTable.vue";

export default {
  components: { MainTemplate, CustomTable },
  data() {
    return {
      isEdit: false,
      showLoading: false,
      showLoadingModal: false,
      items: [],
      fields: [
        { key: "id", label: "#", thStyle: { width: "30px" }, sortable: true },
        {
          key: "name",
          label: "NOMBRE",
          thStyle: { width: "70px" },
          sortable: true,
        },
        {
          key: "email",
          label: "EMAIL",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "created_at",
          label: "FECHA CREACION",
          thStyle: { width: "65px" },
          tdClass: ["text-right"],
          thClass: ["text-right"],
          sortable: true,
        },
        {
          key: "roles",
          label: "ROLES",
          thStyle: { width: "80px" },
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { width: "45px" },
          tdClass: ["text-center"],
          thClass: ["text-center"],
        },
      ],
      perPage: 20,
      currentPage: 1,
      filter: null,
      modalVisible: false,
      user: {
        id: null,
        name: "",
        rol: 0,
        email: "",
        password: "",
        area : 0
      },
      roles: [],
      msjModal: null,
      areas : []
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    editUser(user) {
      this.isEdit = true;
      this.user = {
        id: user.id,
        name: user.name,
        rol: user.roles.length ? user.roles[0].id : null,
        area: user.areas.length ? user.areas[0].id : null,
        email: user.email,
        password: user.password,
      };
      this.showModal();
    },
    getUsers() {
      this.showLoading = true;

      this.$axios
        .get("/admin/getUsers")
        .then((response) => {
          this.items = [...response.data];
          this.showLoading = false;
        })
        .catch((err) => {
          this.showLoading = false;
          //this.msjModal = "Error! " + err;
          //this.$refs["msj-modal"].show();
        });
    },
    //getDetails() {},
    showModal() {
      if (!this.isEdit) {
        this.clearForm();
      }
      this.showLoadingModal = true;
      this.modalVisible = true;

      this.$axios
        .get("/admin/getRoles")
        .then((response) => {
          const rolesOptions = response.data.roles.map((item) => ({
            value: item.id,
            text: item.name,
          }));
          rolesOptions.unshift({
            value: 0,
            text: 'Seleccione un Rol...',
          });
          this.roles = rolesOptions;
          const areasOptions = response.data.areas.map((item) => ({
            value: item.id,
            text: item.name,
          }));
          areasOptions.unshift({
            value: 0,
            text: 'Seleccione un Area...',
          });
          this.areas = areasOptions;
          this.showLoadingModal = false;
        })
        .catch((err) => {
          this.showLoadingModal = false;
          this.msjModal = "Error! " + err;
          this.$refs["msj-modal"].show();
        });
    },
    saveUsuario() {
      if (this.isEdit) {
        this.updateUser();
      } else {
        this.createUser();
      }
    },
    updateUser() {
      this.showLoadingModal = true;
      this.$axios
        .post("/admin/update", {
          // Aquí debes enviar los datos del formulario
          id: this.user.id,
          name: this.user.name,
          role: this.user.rol,
          email: this.user.email,
          area_id: this.user.area,
          password: this.user.password,
        })
        .then((response) => {
          this.getUsers();
          this.modalVisible = false;
          this.showLoadingModal = false;
          this.clearForm();
        })
        .catch((err) => {
          this.showLoadingModal = false;
          //this.msjModal = "Error! " + err;
          //this.$refs["msj-modal"].show();
        });
    },
    validateFields() {
      return (
        this.user.name && this.user.email && this.user.password && this.user.rol //&& this.user.area
      );
    },
    createUser() {
      if (!this.validateFields()) {
        this.msjModal = "Hay campos vacíos o sin seleccionar!";
        this.$refs["msj-modal"].show();
        return;
      }

      this.showLoadingModal = true;
      this.$axios
        .post("/register", {
          // Aquí debes enviar los datos del formulario
          name: this.user.name,
          role: this.user.rol,
          area_id: this.user.area,
          email: this.user.email,
          password: this.user.password,
        })
        .then((response) => {
          this.getUsers();
          this.modalVisible = false;
          this.showLoadingModal = false;
          this.clearForm();
        })
        .catch((err) => {
          //console.log(err.response.data.error);
          this.showLoadingModal = false;
          this.msjModal = err.response.data.error;
          this.$refs["msj-modal"].show();
        });
    },
    clearForm() {
      this.user = {
        id: null,
        name: "",
        rol: 0,
        email: "",
        password: "",
        area : 0
      };
      this.isEdit = false;
    },
  },
};
</script>
<style scoped>
</style>