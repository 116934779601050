<template>
  <div class="container">
    <b-card>
      <b-overlay
        :show="show"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="lg"
      >
        <b-col cols="12">
          <b-row>
            <b-col cols="8">
              <h5>Áreas</h5>
            </b-col>
            <b-col align="right" md="4" sm="12">
              <b-button
                @click="openModalNewArea()"
                size="sm"
                variant="outline-primary"
                title="Nueva Área"
              >
              <b-icon icon="plus-lg"></b-icon>
              </b-button>
            </b-col>
          </b-row>
          <b-row> </b-row>

          <b-row class="pt-2">
            <b-col md="12">
              <b-table
                class="table-rotation"
                responsive
                head-variant="light"
                fixed
                bordered
                small
                striped
                hoverstriped
                hover
                :items="items"
                :fields="fields"
              >
                <template #cell(non_resolving)="row">
                  <b-badge variant="info">{{row.item.non_resolving == 1 ? 'SI' : 'NO'}}</b-badge>
                </template>
                <template #cell(actions)="row">
                  <b-icon
                    @click="openModalUsersArea(row.item)"
                    icon="person-plus-fill"
                    class="h6 m-0 pointer"
                    variant="info"
                    title="Gestionar"
                  ></b-icon>
                  <b-icon
                    @click="openModalEditArea(row.item)"
                    icon="pencil-fill"
                    class="h6 m-0 pointer"
                    variant="warning"
                    title="Editar"
                  ></b-icon>
                </template>
              </b-table>
            </b-col>
            <b-col> </b-col>
          </b-row>
        </b-col>
      </b-overlay>
    </b-card>

    <!-- MODAL New Users Area -->
    <b-modal
      id="modal-user-area"
      :title="'Gestionar usuarios en Área ' + area.name"
      hide-footer
      centered
      size="lg"
      :no-close-on-backdrop="true"
    >
      <b-overlay
        :show="showBtnModal"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="lg"
      >
        <b-col cols="12">
          <b-row>
            <b-col sm="9" md="9">
              <b-form-text for="select-state"> Usuario </b-form-text>
              <model-list-select
                :list="users"
                v-model="user"
                option-value="id"
                option-text="description"
                placeholder="Escriba o seleccione al usuario"
              >
              </model-list-select>
            </b-col>
            <b-col sm="2" md="2">
              <b-button class="mt-3" @click="addUserToArea()" variant="info"
                >Agregar
              </b-button>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" class="p-3">
          <b-row class="row-user" v-for="(user, index) in usersArea" :key="index">
            <b-col
              @click="removeUserArea(user, index)"
              class="pointer"
              cols="1"
            >
              <b-icon style="color:red" icon="trash-fill" aria-hidden="true"></b-icon>
            </b-col>
            <b-col cols="2">
              {{ user.id }}
            </b-col>
            <b-col cols="5">
              {{ user.name }}
            </b-col>
            <b-col cols="3">
              {{ user.email }}
            </b-col>
          </b-row>
        </b-col>
        <b-row>
          <b-col align="left" cols="6">
            <b-button class="mt-3" @click="$bvModal.hide('modal-user-area')"
              >Cancelar</b-button
            >
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
    <!-- MODAL New Area -->
    <b-modal
      id="modal-area"
      title="Nueva Área"
      hide-footer
      centered
      :no-close-on-backdrop="true"
    >
      <b-overlay
        :show="showBtnModal"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="lg"
      >
        <b-col sm="12" md="12">
          <b-form-text for="select-state"> Área </b-form-text>
          <b-form-input
            v-model="name"
            placeholder="Nombre..."
            required
            size="sm"
          ></b-form-input>
        </b-col>
        <b-col cols="12" class="pt-1">
          <b-form-text for="select-state"> No resolutiva </b-form-text>
          <b-form-checkbox
            v-model="nonResolving"
            name="checkbox-1"
          >
            
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" class="p-3">
          <b-form-text for="select-state"> Descripción </b-form-text>
          <b-form-textarea
            id="textarea"
            placeholder="Escribir al menos 10 caracteres..."
            rows="3"
            max-rows="6"
            autofocus
            v-model="description"
          ></b-form-textarea>
        </b-col>
        <b-row>
          <b-col align="left" cols="6">
            <b-button class="mt-3" @click="$bvModal.hide('modal-area')"
              >Cancelar</b-button
            >
          </b-col>
          <b-col align="right" cols="6">
            <b-button class="mt-3" @click="saveArea()" variant="primary"
              >Crear
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
    <!-- MODAL Edit Area -->
    <b-modal
      id="modal-area-edit"
      title="Editar Área"
      hide-footer
      centered
      :no-close-on-backdrop="true"
    >
      <b-overlay
        :show="showBtnModal"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="lg"
      >
        <b-col sm="12" md="12">
          <b-form-text for="select-state"> Área </b-form-text>
          <b-form-input
            v-model="area.name"
            placeholder="Nombre..."
            required
            size="sm"
          ></b-form-input>
        </b-col>
        <b-col cols="12" class="pt-1">
          <b-form-text for="select-state"> No resolutiva </b-form-text>
          <b-form-checkbox
            v-model="area.nonResolving"
            name="checkbox-1"
          >
            
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" class="p-3">
          <b-form-text for="select-state"> Descripción </b-form-text>
          <b-form-textarea
            id="textarea"
            placeholder="Escribir al menos 10 caracteres..."
            rows="3"
            max-rows="6"
            autofocus
            v-model="area.description"
          ></b-form-textarea>
        </b-col>
        <b-row>
          <b-col align="left" cols="6">
            <b-button class="mt-3" @click="$bvModal.hide('modal-area-edit')"
              >Cancelar</b-button
            >
          </b-col>
          <b-col align="right" cols="6">
            <b-button class="mt-3" @click="saveChangeArea()" variant="primary"
              >Modificar
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>

    <!-- Msjs modal -->
    <b-modal id="msj-modal" ref="msj-modal" centered hide-footer hide-header>
      <div class="d-block text-center p-4">
        <h4>{{ msjModal }}</h4>
      </div>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        size="sm"
        @click="$bvModal.hide('msj-modal')"
        >OK</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import { ModelListSelect } from "vue-search-select";

export default {
  components: { ModelListSelect },

  data() {
    return {
      nonResolving : false,
      area: { name: null },
      description: null,
      name: null,
      items: [],
      user: {},
      users: [],
      usersArea: [],
      fields: [
        //{ key: "id", label: "#", thStyle: { width: "35px" } },
        {
          key: "id",
          label: "#",
          thStyle: { width: "25px" },
          tdClass: ["text-center"],
          thClass: ["text-center"],
          sortable: true,
        },
        {
          key: "name",
          label: "ÁREA",
          thStyle: { width: "50px" },
          tdClass: ["text-center"],
          thClass: ["text-center"],
          sortable: true,
        },
        {
          key: "description",
          label: "DESCRIPCIÓN",
          thStyle: { width: "120px" },
          tdClass: ["text-center"],
          thClass: ["text-center"],
          sortable: true,
        },
        {
          key: "non_resolving",
          label: "NO RESOLUIVA",
          tdClass: ["text-center"],
          thClass: ["text-center"],
          thStyle: { width: "50px" },
          sortable: true,
        },
        {
          key: "actions",
          label: "ACCIONES",
          thStyle: { width: "30px" },
          tdClass: ["text-center"],
          thClass: ["text-center"],
          sortable: true,
        },
      ],
      show: false,
      showBtnModal: false,
      msjModal: null,
    };
  },
  mounted() {
    this.getAreas();
  },
  methods: {
    getAreas() {
      this.show = true;
      this.$axios
        .get("/admin/areas/getAreas", {
          params: {
            area_id: 2, //Para que traiga todas las areas
          },
        })
        .then((response) => {
          this.items = [...response.data.areas];
          this.show = false;
        });
    },
    saveArea() {
      this.showBtnModal = true;
      this.$axios
        .post("/admin/areas/save", {
          name: this.name,
          description: this.description,
          non_resolving : this.nonResolving
        })
        .then((response) => {
          this.items.push(response.data);
          this.clear();
        });
    },
    getUsersArea() {
      this.show = true;
      this.$axios
        .get("/admin/areas/getUsers", {
          params: {
            area_id: this.area.id,
          },
        })
        .then((response) => {
          this.users = [...response.data.users];
          this.usersArea = [...response.data.users_area];
          this.formatUsersToSelect();
          this.$bvModal.show("modal-user-area");
          this.show = false;
        });
    },
    addUserToArea() {
      //this.showBtnModal = true;
      this.$axios
        .post("/admin/areas/addUser", {
          user_id: this.user.id,
          area_id: this.area.id,
        })
        .then((response) => {
          this.msjModal = response.data.msj;
          this.$refs["msj-modal"].show();
          if (response.data.status == 1) {
            this.usersArea.push(this.user);
            this.users = this.users.filter((e) => e.id != this.user.id);
          }
          this.user = {};
        });
    },
    removeUserArea(user, index) {
      this.$axios
        .get("/admin/areas/removeUser", {
          params: {
            area_id: this.area.id,
            user_id: user.id,
          },
        })
        .then((response) => {
          this.msjModal = response.data.msj;
          this.$refs["msj-modal"].show();
          this.applyChangesModal(user, index);
        });
    },
    applyChangesModal(user, index) {
      user.description = user.id + " - " + user.name;
      this.users.push(user);
      this.users.sort((a, b) => a.id - b.id);
      this.usersArea.splice(index, 1);
    },
    saveChangeArea() {
      this.$axios
        .put("/admin/areas/saveChangeArea", {
          area_id: this.area.id,
          area_name: this.area.name,
          area_description: this.area.description,
          non_resolving : this.area.nonResolving
        })
        .then((response) => {
          this.msjModal = "Area Modificada!";
          this.$refs["msj-modal"].show();
          this.items = this.items.map((e) => {
            if (e.id == response.data.id) {
              e = response.data;
            }
            return e;
          });
          this.$bvModal.hide("modal-area-edit");
        })
        .catch((err) => {
          this.show = false;
          this.isDelete = false;
          this.msjModal = "Error! " + err;
          this.$refs["msj-modal"].show();
        });
    },
    formatUsersToSelect() {
      this.users = this.users
        .map((user) => {
          user.description = user.id + " - " + user.name;
          return user;
        })
        .filter((e) => {
          const user = this.usersArea.find((ua) => ua.id == e.id);
          if (!user) return e;
        });
    },
    openModalUsersArea(item) {
      this.area = item;
      this.getUsersArea();
    },
    openModalNewArea() {
      this.$bvModal.show("modal-area");
    },
    openModalEditArea(area) {
      area.nonResolving = area.non_resolving ? true : false
      this.area = area;
      
      this.$bvModal.show("modal-area-edit");
    },
    clear() {
      this.showBtnModal = false;
      this.msjModal = "Area creada!";
      this.$refs["msj-modal"].show();
      this.name = null;
      this.description = null;
      this.nonResolving = false;
      this.$bvModal.hide("modal-area");
    },
  },
};
</script>

<style scoped>
.area-icon-user {
  color: rgb(0, 162, 255);
}
.row-user{
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: cadetblue;
}
</style>