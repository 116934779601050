<template>
  <div id="app">
    <Nav> </Nav>

    <Sidebar ref="sideBar"> </Sidebar>
    <div class="content">
      <!-- Contenido principal de la aplicación -->
      <b-overlay :show="show" rounded="sm">
        <div class="contenedor" :class="expandedSidebar ? 'ancho' : ''">
          <b-navbar toggleable="lg" type="dark" class="navbar d-none d-lg-flex">
            <b-button
              @click="expandSidebar('btn')"
              size="sm"
              class="sidebar-toggle primary"
            >
              <b-icon-list></b-icon-list>
            </b-button>
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto"></b-navbar-nav>
            </b-collapse>

            <!-- Contenido de la barra de navegación -->
            <b-navbar-nav
              v-if="isAuthenticated"
              class="ml-auto d-none d-lg-flex"
            >
              <!-- Agrega el icono de usuario y el menú desplegable -->
              <b-nav-item-dropdown right>
                <!-- Icono de usuario -->
                <template v-slot:button-content>
                  <b-icon-person-fill></b-icon-person-fill>{{ user.name }}
                </template>
                <b-col class="pl-4 pt-2 pb-2 info-user">
                  <div>
                    Área:
                    {{ area != undefined ? area.name : "Sin Área asignada" }}
                  </div>
                </b-col>

                <b-dropdown-item @click="logout"
                  ><b-icon-door-open-fill></b-icon-door-open-fill> Cerrar
                  Sesión</b-dropdown-item
                >
                <!-- Puedes agregar más opciones aquí según tus necesidades -->
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-navbar>
          <div class="p-3">
            <router-view />
          </div>
        </div>
      </b-overlay>
    </div>

    <b-toast id="my-toast" variant="primary" solid no-auto-hide>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">{{ noti.title }}</strong>
        </div>
      </template>
      <div @click="redirectToView()">
        {{ noti.body }}
      </div>
    </b-toast>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";
import Nav from "./components/Nav.vue";
import Toast from "./components/Toast.vue";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import store from "./store";
import axios from "axios";

const firebaseConfig = {
  apiKey: "AIzaSyBaE4CLq-kuvJI7yShwhiSXSmlqfa7Hwas",
  authDomain: "tickets-32be8.firebaseapp.com",
  projectId: "tickets-32be8",
  storageBucket: "tickets-32be8.appspot.com",
  messagingSenderId: "252155375254",
  appId: "1:252155375254:web:02d32744bae5c066fc9cb7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging();

// Progressive Enhancement (SW supported)
if (navigator.serviceWorker) {
  // Register the SW
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(function (registration) {})
    .catch(console.log);
}

if (window.Notification) {
  if (Notification.permission === "granted") {
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission((permission) => {
      if (permission === "granted") {
        location.reload();
        navigator.serviceWorker.register("/firebase-messaging-sw.js", {
          scope: "/firebase-cloud-messaging-push-scope",
        });
      } else {
        location.reload();
        navigator.serviceWorker.register("/firebase-messaging-sw.js", {
          scope: "/firebase-cloud-messaging-push-scope",
        });
      }
    });
  }
}
onMessage(messaging, (payload) => {
  //console.log("Message received. ", payload);
  store.commit("setNotification", payload);

  // ...
});

getTokenDevice();

if ("permissions" in navigator) {
  navigator.permissions
    .query({ name: "notifications" })
    .then(function (notificationPerm) {
      notificationPerm.onchange = async function () {
        if (notificationPerm.state == "granted") {
          //Si el usuario activa las notificaciones, obtenemos el token de notificacion y asignamos al usuario
          const tokenDevice = await getTokenDevice();
          axios
            .put("/admin/setTokenDevice", { token_device: tokenDevice })
            .then((response) => {});
        }
      };
    });
}

async function getTokenDevice() {
  return new Promise((resolve, reject) => {
    getToken(messaging, {
      vapidKey:
        "BMz1Q0ukrRKkCn8KXBIxNwEr98FzkhXMnNFkpO6ZMmO2N9EWtC6BpZpOACymOxFt8JjkjN5oFbe9T6uF5YxYkVI",
    })
      .then((currentToken) => {
        if (currentToken) {
          localStorage.setItem("token_notification", currentToken);
          resolve(currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  });
}

import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {
  data() {
    return {
      noti: {
        title: null,
        body: null,
      },
      data: null,
      counter: 0,
      activeToasts: [],

      pathSelected: null,
      expandedSidebar: false,
    };
  },
  components: {
    Sidebar,
    Nav,
  },
  created() {
    // Manejar el evento notificationclick
    navigator.serviceWorker.addEventListener("notificationclick", (event) => {
      // Evitar que la notificación cierre automáticamente
      event.notification.close();

      // Aquí puedes realizar la acción específica que desees
      // Puedes redirigir a una página, ejecutar una función, etc.
      this.handleNotificationClick();
    });
  },
  computed: {
    show() {
      return this.$store.getters.getLoadingApp;
    },
    ...mapState(["permissions"]),

    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    user() {
      return this.$store.getters.getUser;
    },
    area() {
      return this.$store.getters.getArea;
    },
  },
  watch: {
    "$store.state.notification": {
      handler(notification) {
        if (notification) {
          if (notification.hasOwnProperty("notification")) {
            this.noti = notification.notification;
            this.data = notification.data;
            const toastId = this.counter; // Utilizar el counter como identificador único

            const content = {
              component: Toast,
              props: {
                noti: this.noti,
                data: this.data,
                currentPath: this.$route.path,
                toastId: toastId,
              },
            };
            //console.log('hasta aqui bien?');
            // Render the toast and its contents
            this.$toast(content, { position: "top-right", timeout: false });
            this.counter++;
          }
        }
        //his.$bvToast.show("notification-toast");
      },
      immediate: true,
    },
  },
  mounted() {
    this.checkUser();
  },
  methods: {
    ...mapMutations(["changeValueLoading"]),

    checkPermission(permiso) {
      return this.permissions[permiso].status;
    },
    goTo(path) {
      if (this.$route.path == path) {
        return;
      }
      this.$router.push(path).catch(() => {});
    },
    async logout() {
      this.changeValueLoading(true);
      this.$store
        .dispatch("logout")
        .then((res) => {
          if (res.status == 200) {
            // Limpiar el token almacenado en localStorage
            localStorage.removeItem("token");
            this.$store.commit("setAuthentication", false);

            // Redirigir al usuario a la página de inicio de sesión
            this.$router.push("/login").catch(() => {});
          } else if (res.status == 403) {
          }
          this.changeValueLoading(false);
        })
        .catch((err) => {
          this.msg = err.response.data.msg;
          this.changeValueLoading(false);
        });
    },
    closeToast(toastId) {
      this.$bvToast.hide(toastId);
    },
    makeToast(variant = null) {
      const content = {
        component: Toast,
        props: {
          counter: this.counter,
        },
      };

      // Render the toast and its contents
      this.$toast(content, { position: "top-right", timeout: false });
    },
    redirectToView() {
      this.data.id = this.data.orderId;
      this.data.area_id = this.data.areaId;
      this.data.name = this.data.areaName;
      this.data.user_name = this.data.userName;
      const propsData = {
        propOrderId: this.data.orderId,
        orderData: this.data,
      };
      this.$bvToast.hide("my-toast");
      this.$router.push({
        name: "tickets",
        params: propsData,
      });
    },
    handleNotificationClick() {
      // Acción específica al hacer clic en la notificación
      //console.log("Notificación clicada. Realizar acción específica aquí.");
    },
    ...mapMutations([
      "updatePermission",
      "clearPermissions",
      "setUser",
      "setArea",
      "setNotification",
      "changeValueLoading",
    ]),

    checkUser() {
      this.changeValueLoading(true);
      const token = localStorage.getItem("token");

      this.$axios
        .post("/admin/getUser", { token: token })
        .then((response) => {
          this.setArea(response.data.user.areas[0]);
          this.setUser(response.data.user);
          this.updateP(response.data.user);
          this.changeValueLoading(false);
        })
        .catch((err) => {
          this.showLoadingModal = false;
          this.changeValueLoading(false);
        });
    },
    updateP(user) {
      this.clearPermissions();
      user.roles[0].permissions.forEach((permission) => {
        const permissionName = permission.name;
        this.updatePermission({ permission: permissionName, status: true });
      });
    },
    async prueba() {
      const token = localStorage.getItem("token");
      const response = await this.axios.post(
        `/get-user`,
        {
          token: token,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    },

    navigate(route) {
      this.$router.push(route);
    },

    expandSidebar(source) {
      this.expandedSidebar = !this.expandedSidebar;
      //this.$emit('expand-sidebar', source);
      this.$refs.sideBar.expandSidebar(source);
    },
    collapseSidebar(origen) {
      if (!this.isExpandedByBtn) {
        this.isSidebarExpanded = false;
      }
    },
  },
};
</script>

<style>
.sidebar-toggle {
  position: relative;
  padding-left: 5pt;
  color: #3498db;
  background-color: #005187 !important;
  border-color: #005187 !important;
}
.pointer {
  cursor: pointer;
}
.table-rotation td {
  font-size: 9pt;
}
.table-rotation thead {
  font-size: 8pt;
}
.navbar {
  background-color: #005187;
}
.contenedor {
  height: 100vh;
}
.space {
  width: 48pt;
}
#app {
  display: flex;
  position: relative;
  background-color: #f0f5ff !important;
  min-height: 100% !important;
}
html,
body {
  min-height: 100% !important;
}
body {
  background-color: #f0f5ff !important;
}
.span-visible {
  padding-left: 5pt;
  opacity: 1;
  visibility: visible;
  transition: visibility 0.5s, opacity 0.4s;
  position: absolute;
}
.span-hide {
  transition: visibility 0.3s, opacity 0.1s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.sidebar-container {
  position: absolute;
  z-index: 100; /* Asegura que el sidebar esté por encima del contenido principal */
}
.sidebar-container-relative {
  position: relative;
  z-index: 100; /* Asegura que el sidebar esté por encima del contenido principal */
}
.sidebar {
  height: 100vh;
  width: 48pt;
  background-color: #005187;
  color: white;
  /*display: flex;
  flex-direction: column;
  align-items: start;*/
  padding-top: 20px;
  overflow: hidden; /* Evitar que el contenido se desborde cuando expandido */
  transition: width 0.3s; /* Anima el cambio de ancho */
  position: fixed; /* Fija la barra lateral en la parte izquierda */
  top: 0;
  left: 0;
  z-index: 1000; /* Asegura que esté por encima de otros elementos */
}

.sidebar.expanded {
  width: 180pt; /* Ancho expandido */
}

.sidebar-item {
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
}
.content {
  flex: 1;
  margin-left: 48pt;
  transition: margin-left 0.3s;
  min-height: 100%; /* Ajusta la altura mínima al 100% */
}
.ancho {
  margin-left: 130pt;
  transition: margin-left 0.3s ease;
}
@media (max-width: 991px) {
  .content {
    margin-top: 37pt;
    margin-left: 5pt;
  }
}
.sidebar.expanded + .content {
  margin-left: 200px;
}
</style>
