import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.config.productionTip = false

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'vue-search-select/dist/VueSearchSelect.css'


axios.defaults.baseURL = 'https://api.ticketsflow.online/api';
//axios.defaults.baseURL = 'http://tickets.test/api';
//axios.defaults.baseURL = 'http://ec2-18-188-174-180.us-east-2.compute.amazonaws.com/api';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token')
Vue.prototype.$axios = axios;

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)
Vue.use(Toast);

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Código 401: Usuario no autorizado
      localStorage.removeItem("token");
      store.commit("setAuthentication", false);
      if (router.currentRoute.name !== 'login' && router.currentRoute.name !=='privacy_policies'
      ) {
        router.push('/login');
      }    
    }
    return Promise.reject(error);
  }
);


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
